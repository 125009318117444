import React from "react";
import "./Vtour.scss";
import AboutBannerBg from "../VirtualTour/AboutBannerBg.jpg";
import ToolTip3 from "../VirtualTour/ToolTip3.jpg";
import BrickToolTip from "../VirtualTour/BrickToolTip.jpg";
import CulvertToolTip from "../VirtualTour/CulvertToolTip.jpg";
import PoleToolTip from "../VirtualTour/PoleToolTip.jpg";
import PrefabToolTip from "../VirtualTour/BrickToolTip.jpg";

const Vtour = () => {
  const tooltips = document.querySelectorAll(".all-tooltip .tooltip");
  const fullDiv = document.querySelector("section");
  const container = document.querySelector(".container");
  let timeoutId;
  window.addEventListener("resize", contentPosition);
  window.addEventListener("DOMContentLoaded", contentPosition);

  function contentPosition() {
    tooltips.forEach((tooltip) => {
      const pin = tooltip.querySelector(".pin");
      const content = tooltip.querySelector(".tooltip-content");
      const arrow = tooltip.querySelector(".arrow");
      const pinLeft = pin.offsetLeft;
      if (pinLeft + content.offsetWidth / 2 > fullDiv.offsetWidth) {
        const extraLeft =
          fullDiv.offsetWidth - (pinLeft + content.offsetWidth / 2);
        // console.log('right-conflict', tooltip)
        content.style.left =
          pinLeft - content.offsetWidth / 2 + extraLeft - 30 + "px";
        content.style.top = pin.offsetTop + 30 + "px";
      } else if (
        pin.offsetLeft + container.offsetLeft <
        content.offsetWidth / 2
      ) {
        // console.log('left conflict', pin.offsetLeft)
        content.style.left = -container.offsetLeft + "px";
        content.style.top = pin.offsetTop + 30 + "px";
      } else {
        content.style.left = pinLeft - content.offsetWidth / 2 + "px";
        content.style.top = pin.offsetTop + 30 + "px";
      }
      arrow.style.left =
        pinLeft - content.offsetLeft + pin.offsetWidth / 2 + "px";
    });
  }
  tooltips.forEach((tooltip) => {
    const pin = tooltip.querySelector(".pin");
    const content = tooltip.querySelector(".tooltip-content");
    pin.addEventListener("mouseover", () => {
      tooltip.classList.add("active");
    });
    pin.addEventListener("mouseleave", () => {
      timeoutId = setTimeout(() => {
        if (!tooltip.classList.contains("content-hover")) {
          tooltip.classList.remove("active");
        }
      }, 2000);
    });
    content.addEventListener("mouseover", () => {
      clearTimeout(timeoutId);
      tooltip.classList.add("active");
      tooltip.classList.add("content-hover");
    });
    content.addEventListener("mouseleave", () => {
      timeoutId = setTimeout(() => {
        tooltip.classList.remove("active");
        tooltip.classList.remove("content-hover");
      }, 2000);
    });
  });

  return (
    <div>
      {" "}
      <section>
        <div className="container">
          <img src={AboutBannerBg} alt="" />
          <div className="all-tooltip">
            <div className="tooltip tooltip-1">
              <div className="pin">
                <ion-icon name="add-circle"></ion-icon>
              </div>
              <div className="tooltip-content">
                <div className="arrow"></div>
                <div className="img">
                  <img src={PoleToolTip} alt="" />
                </div>
                <div className="content">
                  <h1>This is a title</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit, natus.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit, natus.
                  </p>
                  <button>Buy Now $199</button>
                </div>
              </div>
            </div>
            <div className="tooltip tooltip-2">
              <div className="pin"></div>
              <div className="tooltip-content">
                <div className="arrow"></div>
                <div className="img">
                  <img src={PrefabToolTip} alt="" />
                </div>
                <div className="content">
                  <h1>This is a title</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit, natus.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit, natus.
                  </p>
                  <button>Buy Now $199</button>
                </div>
              </div>
            </div>
            <div className="tooltip tooltip-3">
              <div className="pin"></div>
              <div className="tooltip-content">
                <div className="arrow"></div>
                <div className="img">
                  <img src={PoleToolTip} alt="" />
                </div>
                <div className="content">
                  <h2>Hello world -3</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Voluptatibus molestiae accusantium magni ex numquam eveniet,
                    doloremque dolores impedit beatae quas!
                  </p>
                </div>
              </div>
            </div>
            <div className="tooltip tooltip-4">
              <div className="pin"></div>
              <div className="tooltip-content">
                <div className="arrow"></div>
                <div className="img">
                  <img src={PoleToolTip} alt="" />
                </div>
                <div className="content">
                  <h2>Hello world -4</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Voluptatibus molestiae accusantium magni ex numquam eveniet,
                    doloremque dolores impedit beatae quas!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Vtour;
