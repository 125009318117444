import { useEffect, useRef } from "react";
import TestimonialCard from "./TestimonialCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y, EffectFade } from "swiper";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css";
import "./_Testimonials.scss";
import Dots from "./Dots/Dots";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function Testimonial() {
  const { t } = useTranslation();

  return (
    <div className="container-wrapper testimonal-wrapper">
      <div className="container testimonal container-gap">
        <Fade bottom cascade distance="10%">
          <div className="top">
            <div className="h2">
              <h3 className="test-title"> {t(`Testimonial.title`)}</h3>
            </div>

            <div>
              <p>{t(`Testimonial.text`)}</p>
            </div>
          </div>
        </Fade>
        <div className="card-container">
          <Swiper
            modules={[Pagination, A11y, EffectFade]}
            effect={"Fade"}
            spaceBetween={0}
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              800: {
                slidesPerView: 2,
              },
            }}
          >
            <SwiperSlide>
              <TestimonialCard
                text1={t(`Testimonial.name1`)}
                text2={t(`Testimonial.testimonial1`)}
                text3={t(`Testimonial.speaker1`)}
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard
                text1={t(`Testimonial.name2`)}
                text2={t(`Testimonial.testimonial2`)}
                text3={t(`Testimonial.speaker2`)}
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard
                text1={t(`Testimonial.name3`)}
                text2={t(`Testimonial.testimonial3`)}
                text3={t(`Testimonial.speaker3`)}
              />
            </SwiperSlide>
            <Dots />
          </Swiper>
        </div>
      </div>
    </div>
  );
}


