import "./_BannerHome.scss"
import { useEffect, useState } from "react";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';


export default function BannerHome() {
  const { t } = useTranslation()
  // banner images data
    const slides = [
          "banner1.jpg",
         "HomeBanner2.jpg",
         "banner3.jpg",
  ]
  // banner text data
  const headlines = [
    {
      title: t('banner.1-1'),
      title2: t('banner.1-1-1')
    },
    {
      title: t('banner.1-3'),
      title2: t('banner.1-3-1')
    },
    {
      title: t('banner.1-5'),
      title2: t('banner.1-5-1')
    }
  ]
    // change banner image and text after some time interval
    const [activeIdx, setActiveIdx] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIdx(activeIdx === slides.length - 1 ? 0 : activeIdx + 1);
      }, 4000);
      return () => clearInterval(interval);
    }, [activeIdx, slides.length]);

    return (
      <div className="slide-image-wrapper">
        {/* show current banner image  */}
        {slides.map((data, idx) => {
          return (
            <img key={data}
              className={
                activeIdx === idx
                  ? "slide-image slide-image-active"
                  : "slide-image"
              }
              src={data}
              alt=""
            />
          );
        })}

        {/* Left and Right buttons to change banner image and text */}
        <div className="left-right-btn">
          <span>
              <HiChevronLeft size={90}
                className="prev"
                onClick={() =>
                    setActiveIdx(activeIdx < 1 ? slides.length - 1 : activeIdx - 1)
                }
              />
          </span>
          <span>
              <HiChevronRight size={90}
                className="next"
                onClick={() =>
                    setActiveIdx(activeIdx === slides.length - 1 ? 0 : activeIdx + 1)
                }
              />
          </span>
        </div>
        
        {/* Slightly dark overlay on banner to make texts readable */}
        <div className="overlay"></div>

        {/* Shows total number of slides and the active one, they clickable to change slide */}
        <div className="bottom-bullets">
          {slides.map((data, idx) => {
            return (
              <span key={idx}
                onClick={() => setActiveIdx(idx)}
                className={activeIdx === idx ? "bullet bullet-active" : "bullet"}></span>
            )
          })}
        </div>

        {/* shows current Text Content on banner */}
        <div className="container-wrapper banner-text-wrapper ">
          <div className="container banner-text">
            <Fade left cascade distance="10%">
              <div className="banner-content">
                {headlines.map((data, idx) => {
                  return (
                    activeIdx === idx && <div key={data}>
                      <h1 className="h1">{data.title} <br /> {data.title2}</h1>
                    </div>
                  )
                })}
              
                <NavLink to="/About">
                  <div className="btn2">{t('button.3')}</div>
                </NavLink>
              </div>
            </Fade>
          </div>
        </div>

      </div>
    )
}