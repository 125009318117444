import React from "react";
import "./PrefabVideo.scss";
import ReactPlayer from "react-player/youtube";
import { BsPlayFill } from "react-icons/bs";
import { useTranslation, Trans } from 'react-i18next';

const PrefabVideo = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container-wrapper">
      <div className="container container-gap prefab-videos ">
      <div className="video-title-wrapper">
        <h3 className="video-main-title">{t('productDetails.video')}</h3>
        <hr className="prod-detail-hr-line" />
      </div>
      <div className="video-container-wrapper">
        <div className="prefab-video-container">
          <div className="prefab-video">
            <ReactPlayer
              url="https://youtu.be/tH4lnvM-9LE"
              className="video"
              width='100%'
            height={250}
              controls
            />
          </div>
          <div className="prefab-video-intro">
            <div className="video-intro-title">
              <BsPlayFill size={24} color="#F77B00" /> <h3>{t('buttons.btn3')}</h3>
            </div>
            <p>{t('productDetails.prefab.video.title1')}</p>
          </div>
        </div>
        <div className="prefab-video-container">
          <div className="prefab-video">
            <ReactPlayer
              url="https://youtu.be/uIZ3IZBNr-k"
              className="video"
              width='100%'
            height={250}
              controls
            />
          </div>
          <div className="prefab-video-intro">
            <div className="video-intro-title">
              <BsPlayFill size={24} color="#F77B00" /> <h3>{t('buttons.btn3')}</h3>
            </div>
            <p>{t('productDetails.prefab.video.title2')}</p>
          </div>
        </div>
        <div className="prefab-video-container">
          <div className="prefab-video">
            <ReactPlayer
              url="https://youtu.be/luQWUenqFjQ"
              className="video"
              width='100%'
            height={250}
              controls
            />
          </div>
          <div className="prefab-video-intro">
            <div className="video-intro-title">
              <BsPlayFill size={24} color="#F77B00" /> <h3>{t('buttons.btn3')}</h3>
            </div>
            <p>{t('productDetails.prefab.video.title2')}</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PrefabVideo;
