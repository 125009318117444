import React from 'react'
import { NavLink } from 'react-router-dom'

const ProjectCard = (props) => {
  return (
    <NavLink to={props.link} className='proj-card-container'>
        <div className="card">
                <div className="img-wrapper">
                    <img src={props.url} alt="projects" />
                    <div className="overlay"></div>
                </div>
                <div className="bottom">
                    <div className="project-title"><h4>{props.title}</h4></div>
                    <div ><h5 className="project-text">{ props.text}</h5></div>
                </div>
            </div>
    </NavLink>
  )
}

export default ProjectCard