import "./_CoreValues.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function CoreValues() {
  const { t } = useTranslation();

  // core values icons, title, and text data
  const data = [
    {
      url: "CoreValuesIcons1.png",
      title: t("CoreValues.1"),
      desc: t("CoreValues.2"),
    },
    {
      url: "CoreValuesIcons2.png",
      title: t("CoreValues.3"),
      desc: t("CoreValues.4"),
    },
    {
      url: "CoreValuesIcons3.png",
      title: t("CoreValues.5"),
      desc: t("CoreValues.6"),
    },
    {
      url: "CoreValuesIcons4.png",
      title: t("CoreValues.7"),
      desc: t("CoreValues.8"),
    },
  ];

  return (
    <div className="container-wrapper container-gap core-values-wrapper">
      {/* bottom background section with a different color */}
      <div className="bottom-bg"></div>

      {/* Core values main container */}
      <div className="container core-values">
        {/* top title and sub title section */}
        <Fade bottom distance="10%">
          <div className="top">
            <h3>{t("CoreValues.title1")}</h3>
            <h4 className="h4">{t("CoreValues.title2")}</h4>
          </div>
        </Fade>

        {/* core value items container */}
        <div className="core-values-container">
          {data.map((data, idx) => {
            return (
              // Each core value items
              <Fade left cascade distance="10%">
                <div key={idx} className="core-value-item">
                  {/* icon */}
                  <div className="icon-wrapper">
                    <img src={data.url} alt="" />
                  </div>
                  {/* text content */}
                  <div className="core-value-item-content">
                    <h4>{data.title}</h4>
                    <p>{data.desc}</p>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </div>
    </div>
  );
}
