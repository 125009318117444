import NewsDetail from '../components/News/NewsDetail';
import Banner from "../components/Banner/Banner";
import { useTranslation } from 'react-i18next';
// import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

export default function NewsDetails(props) {
    const { t } = useTranslation()
    const { pid } = useParams()
    
    console.log(pid)
    const pages = [
        "visit-of-council-of-xai-xai",
        "construction-signing-ceremony",
        "a-shared-vision-for-a-better-world"
    ]
    const slides = [
        {
          url: "/NewsBgnew.jpg",
          title: t('banner.10'),
          // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
    ];
    
    const newsData = [
        {
            title: t('NewsDetail1.1'),
            date:"",
            newsText: [
                t('NewsDetail1.2')
            ],
            newsImage: [
                "/news001-img1.jpg",
                "/news001-img2.jpg",
            ]
        },
        {
            title: t('NewsDetail2.1'),
            date:"",
            newsText: [
                t('NewsDetail2.2')
            ],
            newsImage: [
                "/news002-img1.jpg",
            ]
        },
        {
            title: t('NewsDetail3.1'),
            date:"",
            newsText: [
                t('NewsDetail3.2')
            ],
            newsImage: [
                "/news003-img1.jpg",
            ]
        }
        
    ]
    
    const currentNews = newsData[pages.indexOf(pid)]
    // console.log(currentNews)
    return (
        <div>
            {/* <Helmet>
    <title>{t('title.4')}</title>
</Helmet> */}
            <Banner
        slides={slides[0]}
      />
            <NewsDetail
                headline={currentNews.title}
                date={currentNews.date}
                newsText={currentNews.newsText}
                newsImage={currentNews.newsImage}
                pid={pid}
                pages={pages}
                newsData={newsData}
            />
        </div>
    )
}