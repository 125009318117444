import React from "react";
import "../Table.scss";
import { useTranslation, Trans } from 'react-i18next';

const Table = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container-wrapper">
      <div className="container container-gap specification-table">
        <div className="product-spec">
        <h3>Specification</h3>
            <hr className="prod-detail-hr-line"/>
        </div>
        <table className="table-spec-1">
          <thead>
            <th>
              <p>{t('ProductPoleDetail1.3')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.4')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.5')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.6')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.7')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.8')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.9')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.10')}</p>
            </th>
            <th>
              <p>{t('ProductPoleDetail1.11')}</p>
            </th>
          </thead>
          <tbody>
            <tr>
            <td data-label="No.">
                <p>1</p>
              </td>
              <td data-label="Especificações">
                <p>130x7mxCxY</p>
              </td>
              <td data-label="Ferro">
                <p>H4.8</p>
              </td>
              <td data-label="Comprimento">
                <p>7000mm</p>
              </td>
              <td data-label="Diâmetro do topo">
                <p>130mm</p>
              </td>
              <td data-label="Diâmetro da Raiz">
                <p>224mm</p>
              </td>
              <td data-label="Resistência do betão">
                <p>C50</p>
              </td>
              <td data-label="Volume (m²)">
                <p>0.12m³</p>
              </td>
              <td data-label="Peso">
                <p>342kg</p>
              </td>
            </tr>
            <tr>
            <td data-label="No.">
                <p>2</p>
              </td>
              <td data-label="Especificações">
                <p>130x8mxCxY</p>
              </td>
              <td data-label="Ferro">
                <p>H4.8</p>
              </td>
              <td data-label="Comprimento">
                <p>8000mm</p>
              </td>
              <td data-label="Diâmetro do topo">
                <p>130mm</p>
              </td>
              <td data-label="Diâmetro da Raiz">
                <p>236mm</p>
              </td>
              <td data-label="Resistência do betão">
                <p>C50</p>
              </td>
              <td data-label="Volume (m²)">
                <p>0.15m³</p>
              </td>
              <td data-label="Peso">
                <p>427kg</p>
              </td>
            </tr>
            <tr>
            <td data-label="No.">
                <p>3</p>
              </td>
              <td data-label="Especificações">
                <p>150x9mxCxY</p>
              </td>
              <td data-label="Ferro">
                <p>H4.8</p>
              </td>
              <td data-label="Comprimento">
                <p>9000mm</p>
              </td>
              <td data-label="Diâmetro do topo">
                <p>150mm</p>
              </td>
              <td data-label="Diâmetro da Raiz">
                <p>270mm</p>
              </td>
              <td data-label="Resistência do betão">
                <p>C50</p>
              </td>
              <td data-label="Volume (m²)">
                <p>0.19m³</p>
              </td>
              <td data-label="Peso">
                <p>542kg</p>
              </td>
            </tr>
            <tr>
            <td data-label="No.">
                <p>4</p>
              </td>
              <td data-label="Especificações">
                <p>150x10mxCxY</p>
              </td>
              <td data-label="Ferro">
                <p>H4.8</p>
              </td>
              <td data-label="Comprimento">
                <p>10000mm</p>
              </td>
              <td data-label="Diâmetro do topo">
                <p>150mm</p>
              </td>
              <td data-label="Diâmetro da Raiz">
                <p>283mm</p>
              </td>
              <td data-label="Resistência do betão">
                <p>C50</p>
              </td>
              <td data-label="Volume (m²)">
                <p>0.222m³</p>
              </td>
              <td data-label="Peso">
                <p>620kg</p>
              </td>
            </tr>
            <tr>
            <td data-label="No.">
                <p>5</p>
              </td>
              <td data-label="Especificações">
                <p>190x12mxCxY</p>
              </td>
              <td data-label="Ferro">
                <p>H4.8</p>
              </td>
              <td data-label="Comprimento">
                <p>12000mm</p>
              </td>
              <td data-label="Diâmetro do topo">
                <p>190mm</p>
              </td>
              <td data-label="Diâmetro da Raiz">
                <p>350mm</p>
              </td>
              <td data-label="Resistência do betão">
                <p>C50</p>
              </td>
              <td data-label="Volume (m²)">
                <p>0.42m³</p>
              </td>
              <td data-label="Peso">
                <p>1180kg</p>
              </td>
            </tr>
            <tr>
            <td data-label="No.">
                <p>5</p>
              </td>
              <td data-label="Especificações">
                <p>190x15mxCxY</p>
              </td>
              <td data-label="Ferro">
                <p>H4.8</p>
              </td>
              <td data-label="Comprimento">
                <p>15000mm</p>
              </td>
              <td data-label="Diâmetro do topo">
                <p>190mm</p>
              </td>
              <td data-label="Diâmetro da Raiz">
                <p>390mm</p>
              </td>
              <td data-label="Resistência do betão">
                <p>C50</p>
              </td>
              <td data-label="Volume (m²)">
                <p>0.57m³</p>
              </td>
              <td data-label="Peso">
                <p>1625kg</p>
              </td>
            </tr>
      
      
      
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
