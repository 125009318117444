// Navbar styles
import "./Navbar.scss";

// Navbar logo
import logo from "../../img/logo.png";

// For routing to different pages
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// React hooks
import { useEffect, useState } from "react";

// For language translation
import { useTranslation, Trans } from "react-i18next";

// Icons from react-icons
import { RiWhatsappFill } from "react-icons/ri";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaWhatsapp, FaYoutube, FaFacebook } from "react-icons/fa";
import { IoIosMenu, IoIosClose } from "react-icons/io";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { ProductData } from "../ProductData";

export default function Navbar() {
  // For routing to specified path
  const navigate = useNavigate();
  const goToTop = (path) => {
    navigate(path);
  };

  // Variables to control behavior of elements
  const [state, setState] = useState({
    isMenuOpen: false,
    isHideTopNav: false,
    isAboutOpen: false,
    isProductOpen: false
  });

  // Variable to control current page behavior

  // Toggle Mobile Navbar
  function toggleMenu() {
    setState((prevState) => {
      return {
        ...prevState,
        isMenuOpen: !prevState.isMenuOpen,
        isAboutOpen: false,
        isProductOpen: false
      };
    });
  }

  // closing mobile menu opening when user changes from pages to pages
  const location = useLocation();
  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        isMenuOpen: false
      };
    });
  }, [location.pathname]);

  // Hide and show top row of navbar on scroll
  useEffect(() => {
    // console.log(window.scrollY);
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 241) {
        // Hide top row of navbar
        setState((prevState) => {
          return {
            ...prevState,
            isHideTopNav: true
          };
        });
      } else {
        // Show top row of navbar
        setState((prevState) => {
          return {
            ...prevState,
            isHideTopNav: false
          };
        });
      }
    });
  });

  // Show About dropdown for screens > 1024
  function showAbout() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          isAboutOpen: true
        };
      });
    }
  }

  // Hide About dropdown for screens > 1024
  function hideAbout() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          isAboutOpen: false
        };
      });
    }
  }

  // Show Product dropdown for screens > 1024
  function showProduct() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          isProductOpen: true
        };
      });
    }
  }

  // Hide Product dropdown for screens > 1024
  function hideProduct() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          isProductOpen: false
        };
      });
    }
  }

  // Toggle About dropdown for screens < 1024
  function toggleAbout() {
    if (window.innerWidth < 1025) {
      setState((prevState) => {
        return {
          ...prevState,
          isAboutOpen: !prevState.isAboutOpen,
          isProductOpen: false
        };
      });
    }
  }

  // Toggle Product dropdown for screens < 1024
  function toggleProduct() {
    if (window.innerWidth < 1025) {
      setState((prevState) => {
        return {
          ...prevState,
          isProductOpen: !prevState.isProductOpen,
          isAboutOpen: false
        };
      });
    }
  }

  // Change language based on specified language input
  const { t, i18n } = useTranslation();
  function clickLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  // Identifying current main pages url, used to make dropdown link's parent active
  function currentPage() {
    if (location.pathname === "/About" || location.pathname === "/Team") {
      return "about";
    }
    for (let i = 0; i < ProductData.length; i++) {
      if (ProductData[i].url === location.pathname) {
        return "product";
      }
      for (let j = 0; j < ProductData[i].subpages.length; j++) {
        if (ProductData[i].subpages[j] === location.pathname) {
          return "product";
        }
      }
    }
  }

  // used to identify subpages parents
  function currentSubpage() {
    for (let j = 0; j < ProductData[0].subpages.length; j++) {
      if (ProductData[0].subpages[j] === location.pathname) {
        return "gypsum";
      }
    }

    for (let j = 0; j < ProductData[1].subpages.length; j++) {
      if (ProductData[1].subpages[j] === location.pathname) {
        return "prefab";
      }
    }

    for (let j = 0; j < ProductData[2].subpages.length; j++) {
      if (ProductData[2].subpages[j] === location.pathname) {
        return "brick";
      }
    }

    for (let j = 0; j < ProductData[3].subpages.length; j++) {
      if (ProductData[3].subpages[j] === location.pathname) {
        return "pipe";
      }
    }

    for (let j = 0; j < ProductData[4].subpages.length; j++) {
      if (ProductData[4].subpages[j] === location.pathname) {
        return "pole";
      }
    }
  }

  console.log(currentPage());

  return (
    <div className="navbar-wrapper">
      <div className={state.isHideTopNav ? "navbar navbar-fixed" : "navbar"}>
        {/* top navbar row */}
        {!state.isHideTopNav && (
          <div className="container-wrapper top-wrapper">
            <div className="container top">
              {/* Left */}
              <div className="top-left">
                <span className="icon">
                  <TbDeviceLandlinePhone />
                </span>
                <p className="cta-text">{t("nav.19")}</p>
                <p className="phone">+258 864 056 883</p>
              </div>

              {/* Right */}
              <div className="top-right">
                {/* Social Media Icons */}
                <div className="social-media">
                  <span>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/anjiaarquitecture/?_rdc=1&_rdr"
                    >
                      <FaFacebook />
                    </a>
                  </span>
                  <span>
                    <RiWhatsappFill />
                  </span>
                  <a
                    href="https://www.youtube.com/watch?v=luQWUenqFjQ&list=PL5R6lmEL79Xx4ECWY2526He3SsEsJNfJx&pp=gAQBiAQB"
                    target="_blank"
                  >
                    {" "}
                    <span>
                      <FaYoutube />
                    </span>
                  </a>
                </div>

                {/* Change Language buttons */}
                <div className="nav-lang">
                  <p
                    className="nav-text"
                    onClick={() => clickLanguage("en")}
                    style={{
                      color: i18n.resolvedLanguage === "en" ? "#009cad" : ""
                    }}
                  >
                    EN
                  </p>
                  <span className="bar">|</span>
                  <p
                    className="nav-text"
                    onClick={() => clickLanguage("cn")}
                    style={{
                      color: i18n.resolvedLanguage === "pt" ? "#009cad" : ""
                    }}
                  >
                    PT
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* bottom navbar row */}
        <div
          className={
            state.isMenuOpen
              ? "bottom-wrapper menu-open"
              : "container-wrapper bottom-wrapper"
          }
        >
          <div className="container bottom">
            <div className="bottom-left">
              <img onClick={() => goToTop("/")} src={logo} alt="logo" />
            </div>
            <div
              className={
                state.isMenuOpen ? "bottom-right menu-open" : "bottom-right "
              }
            >
              <div className="item text" onClick={toggleMenu}>
                <NavLink to="/" className="item1">
                  {t("nav.1")}
                </NavLink>
              </div>
              <div className="item text">
                <div
                  className="nav-dropdown-wrapper"
                  onMouseEnter={showAbout}
                  onMouseLeave={hideAbout}
                  onClick={toggleAbout}
                >
                  <div
                    style={{
                      color: currentPage() === "about" ? "#009cad" : ""
                    }}
                  >
                    <span>{t("nav.2")}</span>{" "}
                    {state.isAboutOpen ? (
                      <span>
                        <BiChevronUp />
                      </span>
                    ) : (
                      <span>
                        <BiChevronDown />
                      </span>
                    )}
                  </div>
                  {state.isAboutOpen && (
                    <ul className="nav-dropdown">
                      <NavLink to="/About" onClick={toggleMenu}>
                        <li>
                          <span>{t("nav.3")}</span>
                        </li>
                      </NavLink>
                      <NavLink to="/Team" onClick={toggleMenu}>
                        <li>
                          <span>{t("nav.4")}</span>
                        </li>
                      </NavLink>
                    </ul>
                  )}
                </div>
              </div>
              <div className="item text">
                <div
                  className="nav-dropdown-wrapper"
                  onMouseEnter={showProduct}
                  onMouseLeave={hideProduct}
                  onClick={toggleProduct}
                >
                  <div
                    style={{
                      color: currentPage() === "product" ? "#009cad" : ""
                    }}
                  >
                    <span>{t("nav.5")}</span>{" "}
                    {state.isProductOpen ? (
                      <span>
                        <BiChevronUp />
                      </span>
                    ) : (
                      <span>
                        <BiChevronDown />
                      </span>
                    )}
                  </div>
                  {state.isProductOpen && (
                    <ul className="nav-dropdown">
                      <NavLink to="/products/gypsum-board" onClick={toggleMenu}>
                        <li>
                          <span
                          style={{
                            color: currentSubpage() === "gypsum" ? "#009cad" : ""
                          }}
                          >{t("nav.17")}</span>
                        </li>
                      </NavLink>
                      <NavLink to="/products/prefab" onClick={toggleMenu}>
                        <li>
                          <span
                          style={{
                            color: currentSubpage() === "prefab" ? "#009cad" : ""
                          }}
                          >{t("nav.6")}</span>
                        </li>
                      </NavLink>
                      <NavLink to="/products/brick" onClick={toggleMenu}>
                        <li>
                          <span
                          style={{
                            color: currentSubpage() === "brick" ? "#009cad" : ""
                          }}
                          >{t("nav.9")}</span>
                        </li>
                      </NavLink>

                      <NavLink to="/products/pipe" onClick={toggleMenu}>
                        <li>
                          <span
                          style={{
                            color: currentSubpage() === "pipe" ? "#009cad" : ""
                          }}
                          >{t("nav.7")}</span>
                        </li>
                      </NavLink>
                      <NavLink to="/products/pole" onClick={toggleMenu}>
                        <li>
                          <span
                          style={{
                            color: currentSubpage() === "pole" ? "#009cad" : ""
                          }}
                          >{t("nav.8")}</span>
                        </li>
                      </NavLink>
                    </ul>
                  )}
                </div>
              </div>
              <div className="item text" onClick={toggleMenu}>
                <NavLink to="/Project" className="item1">
                  {t("nav.10")}
                </NavLink>
              </div>
              <div className="item text" onClick={toggleMenu}>
                <NavLink to="/News" className="item1">
                  {t("nav.11")}
                </NavLink>
              </div>
              <div className="item text" onClick={toggleMenu}>
                <NavLink to="/Certifications" className="item1">
                  {t("nav.12")}
                </NavLink>
              </div>
              <div className="btn2" onClick={() => goToTop("/Contact")}>
                <span onClick={toggleMenu}>{t("nav.18")}</span>
              </div>
              <div className="social-media">
                <span onClick={toggleMenu}>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/anjiaarquitecture/?_rdc=1&_rdr"
                  >
                    <FaFacebook />
                  </a>
                </span>
                <span onClick={toggleMenu}>
                  <RiWhatsappFill />
                </span>
                <span onClick={toggleMenu}>
                  <FaYoutube />
                </span>
              </div>
            </div>
            <div className="mobile-menu" onClick={toggleMenu}>
              {state.isMenuOpen ? <IoIosClose /> : <IoIosMenu />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
