import Card from "../components/Card";
import { Products } from "../components/ProductData";
import { useTranslation } from "react-i18next";
import ProductList from "../components/ProductList";
import { useParams } from "react-router-dom";
import Banner from "../components/Banner/Banner";
import "../components/products-styles.scss"

export default function ProductCatagory() {
  const { t } = useTranslation();
  const { pid, sid } = useParams();
  console.log(pid)
  const pages = ["prefab", "brick", "gypsum-board", "pipe", "pole"];

  const slides = [
    {
      url: "/product-banner.png",
      title: t("nav.5"),
      body: t("banner.4")
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }
  ];

  let currentProduct = Products.filter(
      (filteredData) => filteredData.catagory == sid
    ).map((data) => {
      return data;
    });
  
  console.log(currentProduct);

  return (
    <>
      <div className="banner-main-container">
        <Banner slides={slides[0]} />
      </div>
      <div className="container-wrapper">
        <div className="product--container container container-gap">
          <ProductList />
          <div className="card-list">
            {currentProduct.map((data, index) => {
              return (
                <Card
                  key={index}
                  img={data.img}
                  alt={data.alt}
                  name={t(`${data.name}`)}
                  url={data.url}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
