import img from "../img/contact.jpg"
import phone from "../img/phone--icon.png"
import mail from "../img/mail--icon.png"
import location from "../img/location--icon.png"
import msg from "../img/msg-icon.png"
import { NavLink, useNavigate } from "react-router-dom";

export default function SidebarContact(props) {

    const navigate = useNavigate();
    const goToTop = (path) => {
      navigate(path);
      window.scrollTo(0, 0);
    };

    return (
        <div className="sidebar-contact">
            <img src={img} alt="contact us" />
            <div className="sidebar-contact--content">
                <div className="sidebar-contact--info">
                    <img className="contact--icon" src={phone} alt="" />
                    <div>
                        <h3>{props.title1}</h3>
                        <p>{ props.content1}</p>
                    </div>
                </div>
                <div className="sidebar-contact--info">
                    <img className="contact--icon" src={mail} alt="" />
                    <div>
                        <h3>{props.title2}</h3>
                        <p>{ props.content2}</p>
                    </div>
                </div>
                <div className="sidebar-contact--info">
                    <img className="contact--icon" src={props.icon3} alt="" />
                    <div>
                        <h3>{props.title3}</h3>
                        <a href={props.url} target="_blank">
                            <p className="sidebar-contact-text">{ props.content3}</p>
                        </a>
                    </div>
                </div>
               
            </div>
            <a href={props.contact} target="_blank"> <div className="sidebar-contact--btn" >
                    <img src={msg} alt="contact"/> <span>CONTACT US NOW</span>
                </div></a>
        </div>
    )
}