import React from 'react';
import { useSwiper } from 'swiper/react';

 const Dots = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns">
      <span onClick={() => swiper.slidePrev()}></span>
      <span onClick={() => swiper.slideNext()}></span>
    </div>
  );
};

export default Dots