import React from "react";
import "./_ProjectCases.scss";
import { useTranslation, Trans } from "react-i18next";
import SchoolProject from "../../img/SchoolProjectImg1.jpg";
import OfficeProject from "../../img/OfficeProjectImg1.png";
import ProjectCard from "./ProjectCard";
import Fade from "react-reveal/Fade";

function ProjectCases() {
  const { t, i18n } = useTranslation();
  const projectData = [
    {
      url: OfficeProject,
      title: t(`Project.Main.title`),
      text: t(`Project.Main.text`),
      link: "/Project/Office_Building_Project",
    },
    {
      url: SchoolProject,
      title: t(`Project.Main.title2`),
      text: t(`Project.Main.text2`),
      link: "/Project/School_Building_Project",
    },
  ];

  return (
    <div className="container-wrapper container-gap">
      <div className="container proj-wrapper">
        <Fade bottom distance="10%">
          <div className="proj-header">
            <h2>{t(`Project.header`)}</h2>
            <p>{t(`Project.text`)}</p>
          </div>
        </Fade>
        <Fade bottom distance="10%">
          <div className="proj-card-wrapper">
            {projectData.map((newData) => {
              return (
                <ProjectCard
                  url={newData.url}
                  title={newData.title}
                  text={newData.text}
                  link={newData.link}
                />
              );
            })}
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default ProjectCases;
