import React from "react";
import "../App.css";
import "../components/Banner/_Banner.scss";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import Leaders from "../components/Leaders/Leaders";
import { useTranslation, Trans } from 'react-i18next';
import OurTeam from "../components/OurTeam/OurTeam";

function Team() {
  const { t, i18n } = useTranslation();
  const slides = [
    {
      url: "banner5.jpg",
      title: t('nav.4'),
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      <div className="banner-main-container">
        <Banner slides={slides[0]} />
      </div>
      < Leaders />
      <OurTeam />
      </>
  );
}

export default Team;
