import React from "react";
import "./RelatedProducts.scss";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

const RelatedProducts = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container-wrapper">
      <div className="container container-gap related-prod-container">
        <div className="related-prod-title">
          <h3>{t('productDetails.related')}</h3>
          <hr className="prod-detail-hr-line" />
        </div>
        <div className="related-prod-cards">
          {props.related.map((data, idx) => {
            return (
              <div key={idx}  className="related-prod-img">
            <NavLink to={data.link}>
              <div className="prod-related-zoom">
                <img src={data.img} className="prod-card-img" />
              </div>
              <div className="prod-card-title">
                <h4> {data.name}</h4>
              </div>
            </NavLink>
          </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
