import "./SidebarContact.scss";
import { MdLocationOn, MdOutlineMarkEmailRead } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export default function SidebarContact() {

  const {t} = useTranslation()

  return (
    <div className="second-sidebar-contact">
      <div className="title">{t('Contacts.7')}</div>
      <div className="text">
      </div>
      <div className="phone">
        <span className="icon">
          <TbDeviceLandlinePhone />
        </span>
        <span>(+258) 864 056 883</span>
      </div>
      <div className="email">
        <span className="icon">
          <MdOutlineMarkEmailRead />
        </span>
        <span>anjiamoz@westholdinggroup.com</span>
      </div>
      <div className="address">
        <span className="icon">
          <MdLocationOn />
        </span>
        <span>Mudada, Bela Vista, Matutuine, 2200, Maputo</span>
      </div>
    </div>
  );
}
