import React from "react";
import "./ProjectDetail.scss";
import  SidebarContact from "../News/SidebarContact"
import PageNumber from "../News/PageNumber";
import SliderImage from "../News/SliderImage";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const ProjectDetail = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="container-wrapper news-detail-wrapper">
    <div className="container container-gap news-detail">
    <div className="news-detail-left">
          <div className="top">
            <div className="title">Latest Projects</div>
            <div className="row-wrapper">
              <div className="row">
                {props.newsData.map((data, idx) => {
                  if (idx < 2) {
                    return (
                      <NavLink to={"/Project/" + props.pages[idx]} key = {idx} >
                        <div
                          className={
                            "/Project/" + props.pages[idx] === location.pathname
                              ? "news-detail-card news-detail-card-active"
                              : "news-detail-card"
                          }
                        >
                          <div className="news-detail-imgs">
                            <img src={data.newsImage[0]} alt="" />
                          </div>
                          <div className="sidebar-content">
                            <div className="headline">{data.title}</div>
                            <div className="date">{data.date}</div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="sidebar-contact-wrapper">
            <SidebarContact />
          </div>
        </div>
        <div className="news-detail-right">
          <h3 className="headline">{props.headline}</h3>
          <p className="date">{props.date}</p>

          <SliderImage newsImage={props.newsImage} />

          {props.newsText.map((data, idx) => {
            return <p key = {idx} className="text">{data}</p>;
          })}

          <PageNumber pid={props.pid} newsData={props.newsData} pages={props.pages} mainPage="/Project/" />
        </div>
    </div>
    </div>
  );
};

export default ProjectDetail;
