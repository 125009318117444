import React from "react";
import "../Table.scss";
import { useTranslation, Trans } from 'react-i18next';

const Table = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container-wrapper ">
       <div className="container container-gap specification-table" >
      <div className="product-spec">
      <h3>Specification</h3>
          <hr className="prod-detail-hr-line"/>
      </div>
      <table className="table-spec-1">
        <thead>
          <th>
            <p>{t('ProductPipeDetail1.3')}</p>
          </th>
          <th>
            <p>{t('ProductPipeDetail1.4')}</p>
          </th>
          <th>
            <p>{t('ProductPipeDetail1.5')}</p>
          </th>
          <th>
            <p>{t('ProductPipeDetail1.6')}</p>
          </th>
        </thead>
        <tbody>
          <tr>
            <td data-label={t('ProductPipeDetail1.3')}>
              <p>1</p>
            </td>
            <td data-label={t('ProductPipeDetail1.4')}>
              <p>θ300mm</p>
            </td>
            <td data-label={t('ProductPipeDetail1.4')}>
              <p>2500mm</p>
            </td>
            <td data-label={t('ProductPipeDetail1.4')}>
              <p>C30</p>
            </td>
          </tr>
          <tr>
            <td data-label="No.">
              <p>2</p>
            </td>
            <td data-label="Especificações">
              <p>θ400mm</p>
            </td>
            <td data-label="Comprimento">
              <p>2500mm</p>
            </td>
            <td data-label="Resistência do betão">
              <p>C30</p>
            </td>
          </tr>
          <tr>
            <td data-label="No.">
              <p>3</p>
            </td>
            <td data-label="Especificações">
              <p>θ500mm</p>
            </td>
            <td data-label="Comprimento">
              <p>2500mm</p>
            </td>
            <td data-label="Resistência do betão">
              <p>C30</p>
            </td>
          </tr>
          <tr>
            <td data-label="No.">
              <p>4</p>
            </td>
            <td data-label="Especificações">
              <p>Φ600mm</p>
            </td>
            <td data-label="Comprimento">
              <p>2500mm</p>
            </td>
            <td data-label="Resistência do betão">
              <p>C30</p>
            </td>
          </tr>
          <tr>
            <td data-label="No.">
              <p>5</p>
            </td>
            <td data-label="Especificações">
              <p>Φ800mm</p>
            </td>
            <td data-label="Comprimento">
              <p>2500mm</p>
            </td>
            <td data-label="Resistência do betão">
              <p>C30</p>
            </td>
          </tr>
          <tr>
            <td data-label="No.">
              <p>6</p>
            </td>
            <td data-label="Especificações">
              <p>Φ1000mm</p>
            </td>
            <td data-label="Comprimento">
              <p>2500mm</p>
            </td>
            <td data-label="Resistência do betão">
              <p>C30</p>
            </td>
          </tr>
          <tr>
            <td data-label="No.">
              <p>7</p>
            </td>
            <td data-label="Especificações">
              <p>Φ1200mm</p>
            </td>
            <td data-label="Comprimento">
              <p>2500mm</p>
            </td>
            <td data-label="Resistência do betão">
              <p>C30</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
   </div>
  );
};

export default Table;
