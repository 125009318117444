import {useState, useRef}from 'react';
import { FaPlus } from "react-icons/fa"

export default function CollapsibleHeader(props) {

    const [openMain, setOpenMain] = useState(true)
    function toggleMain() {
        setOpenMain(!openMain)
    }

    const contentMainRef = useRef()

    return (
        <div>
        <div className='collpsible-main--header' onClick={toggleMain} >
            <h3>{props.label}</h3>
            <span className='collpsible--icon'>
                <FaPlus />
            </span></div>
  
                   { openMain && <div className='main-content'>{props.children} </div> }
    </div>
    )
}