import "./_Testimonials.scss";
import Quote from "../../img/quote_icon.svg";
import { useTranslation } from "react-i18next";

export default function TestimonialCard(props) {
  const { t } = useTranslation();


  return (
    <div className="card">
      <div className="img-wrapper">
        <img src={Quote} alt="" />
      </div>
      <div className="testim-content">
        <div className="p1">{props.text2}</div>
        <div className="testimonial-info make-active">
          <h5 className="test-name">{props.text1} -</h5>
          <span className="test-position"> {props.text3} </span>
        </div>
      </div>
    </div>
  );
}

// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";
// // import React, { Component, useState } from "react";
// // import Slider from "react-slick";
// // import Prefabhome from "../../img/About-bg.jpg";
// // import "./_Testimonials.scss"
// // import { BsArrowRight } from "react-icons/bs";
// // import TestimonialPerson1 from "./TestimonialPerson1.png";
// // import { RiDoubleQuotesL } from "react-icons/ri";
// // import { withTranslation } from 'react-i18next';
// // import img1 from "./image1.png";
// // import img2 from "./img1.png";

// // class TestimonialsSlider extends Component {
// //   render() {
// //     const { t } = this.props;

// //     var settings = {
// //       dots: true,
// //       infinite: true,
// //       speed: 500,
// //       slidesToShow: 3,
// //       slidesToScroll: 3,
// //       initialSlide: 0,
// //       responsive: [
// //         {
// //           breakpoint: 1580,
// //           settings: {
// //             slidesToShow: 2,
// //             slidesToScroll: 2,
// //             infinite: true,
// //             dots: true
// //           }
// //         },
// //         {
// //           breakpoint: 980,
// //           settings: {
// //             slidesToShow: 1,
// //             slidesToScroll: 1,
// //             initialSlide: 2
// //           }
// //         },
// //         {
// //           breakpoint: 480,
// //           settings: {
// //             slidesToShow: 1,
// //             slidesToScroll: 1
// //           }
// //         }
// //       ]
// //     };

// //     return (
// //       <div data-aos="fade-up"
// //       data-aos-duration="2000">
// //         <Slider {...settings}>
// //            <div>
// //           <div className="testimonial-card">
// //             <div className="testimonial-icon">
// //               <RiDoubleQuotesL size="5rem" color="#F77B00" />
// //             </div>
// //             <div class="testimonial-body">
// //               <div className="testimonial-line-y"></div>
// //               <div className="testimonial-text">
// //                 <p>
// //                   {t('Testimonials.9')}
// //                 </p>
// //               </div>
// //             </div>
// //             <div className="testimonial-people">
// //                 <img src={img2} alt="TestimonialPerson1" ></img>
// //                 <div>
// //               <p className="testimonial-name">{t('Testimonials.10')}</p>
// //                   <p className="testimonial-status">{t('Testimonials.11')}</p>
// //                   </div>
// //             </div>
// //           </div>
// //           </div>

// //           <div>
// //           <div className="testimonial-card">
// //             <div className="testimonial-icon">
// //               <RiDoubleQuotesL size="5rem" color="#F77B00" />
// //             </div>
// //             <div class="testimonial-body">
// //               <div className="testimonial-line-y"></div>
// //               <div className="testimonial-text">
// //                 <p>
// //                   {t('Testimonials.3')}
// //                 </p>
// //               </div>
// //             </div>
// //             <div className="testimonial-people">
// //               <img src={img1} alt="TestimonialPerson1" ></img>
// //               <p className="testimonial-name">{t('Testimonials.4')}</p>
// //               <p className="testimonial-status">{t('Testimonials.5')}</p>
// //             </div>
// //           </div>
// //           </div>

// //           <div>
// //           <div className="testimonial-card">
// //             <div className="testimonial-icon">
// //               <RiDoubleQuotesL size="5rem" color="#F77B00" />
// //             </div>
// //             <div class="testimonial-body">
// //               <div className="testimonial-line-y"></div>
// //               <div className="testimonial-text">
// //                 <p>
// //                 {t('Testimonials.6')}
// //                 </p>
// //               </div>
// //             </div>
// //             <div className="testimonial-people">
// //               <img src={img1} alt="TestimonialPerson1" ></img>
// //               <p className="testimonial-name">{t('Testimonials.7')}</p>
// //               <p className="testimonial-status">{t('Testimonials.8')}</p>
// //             </div>
// //           </div>
// //           </div>

// //           {/* <div>
// //           <div className="testimonial-card">
// //             <div className="testimonial-icon">
// //               <RiDoubleQuotesL size="5rem" color="#F77B00" />
// //             </div>
// //             <div class="testimonial-body">
// //               <div className="testimonial-line-y"></div>
// //               <div className="testimonial-text">
// //                 <p>
// //                 {t('Testimonials.9')}
// //                 </p>
// //               </div>
// //             </div>
// //             <div className="testimonial-people">
// //               <img src={img1} alt="TestimonialPerson1" ></img>
// //               <p className="testimonial-name">{t('Testimonials.10')}</p>
// //               <p className="testimonial-status">{t('Testimonials.11')}</p>
// //             </div>
// //             </div>

// //           </div> */}

// //         </Slider>
// //       </div>
// //     );
// //   }
// // }
// // export default withTranslation()(TestimonialsSlider);
