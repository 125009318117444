import React from "react";
import VirtualtourDetail4 from "../../components/VirtualTour/VirtualtourDetail4";

const VirtualTourDetail4 = () => {
  return (
    <div>
      <VirtualtourDetail4 />
    </div>
  );
};

export default VirtualTourDetail4;
