import React from "react";
import "../App.css";
import Virtualtour from "../components/VirtualTour/Virtualtour";

const Vertual = () => {
  return (
    <div>
      <Virtualtour />
    </div>
  );
};

export default Vertual;
