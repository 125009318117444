import React from "react";
import "./_Contact.scss";
import { HiOutlinePlusSm } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Fade from "react-reveal/Fade";

const Form = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    values: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: ""
    },
    isSubmitting: false,
    isError: false,
  });

  let submitForm = async (e) => {
    e.preventDefault();
    setState({ isSubmitting: true });

    const res = await fetch("https://2tdldn40bk.execute-api.eu-west-1.amazonaws.com/prod/contact", {
      method: "POST",
      body: JSON.stringify(state.values),
      headers: {
        "Content-Type": "application/json"
      }
    });
    setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? setState({ message: data.success })
      : setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        setState({
          isError: false,
          message: "",
          values: { name: "", email: "", phone: "", subject: "", message: "" }
        }),
      1600
    );
  };

  let handleInputChange = (e) => {
    setState({
      values: { ...state.values, [e.target.name]: e.target.value }
    });
    // console.log(state)
  };

  return (
    <div className="container-wrapper container-gap">
      <div className="container contact-form">
        <Fade bottom distance="10%">
          <div className="form-header">
            <h2>{t(`Form.title`)}</h2>
            <p>{t(`Form.description`)}</p>
          </div>
        </Fade>
        <form className="form-intro" onSubmit={submitForm}>
          <div className="form-row">
            <div className="row-1">
              <div className="input-wrapper">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder={t(`Form.placeholderName`)}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-wrapper">
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  placeholder={t(`Form.placeholderEmail`)}
                />
              </div>
              <div className="input-wrapper">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={handleInputChange}
                  placeholder={t(`Form.placeholderPhone`)}
                />
              </div>
              <div className="input-wrapper">
                <input
                  id="subject"
                  name="subject"
                  onChange={handleInputChange}
                  placeholder={t(`Form.placeholderSubject`)}
                />
              </div>
            </div>
            <div className="row-2">
              <div className="input-wrapper">
                <textarea
                  id="message"
                  name="message"
                  onChange={handleInputChange}
                  placeholder={t(`Form.placeholderMessage`)}
                />
              </div>
            </div>
          </div>

          <div className="form-btn-wrapper">
            <button type="submit" className="btn-second">
              <span>{t(`Form.submitBtn`)}</span>
              <HiOutlinePlusSm />
            </button>
          </div>
        </form>
        <div className={`message ${state.isError && "error"}`}>
          {state.isSubmitting ? "Submitting..." : state.message}
        </div>
      </div>
    </div>
  );
};

export default Form;
