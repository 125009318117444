import React from "react";
import "../App.css";
import "../components/Banner/_Banner.scss";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import { useTranslation, Trans } from 'react-i18next';

function Privacy() {
  const { t, i18n } = useTranslation();
  const slides = [
    {
      url: "PrivacyBanner.jpg",
      title: t('banner.14'),
    },
  ];
  return (
    <div>
      <Banner slides={slides[0]} />
      < PrivacyPolicy />
    </div>
  );
}

export default Privacy;