import React from "react";
import VirtualtourDetail1 from "../../components/VirtualTour/VirtualtourDetail1";

const VirtualTourDetail1 = () => {
  return (
    <div>
      <VirtualtourDetail1 />
    </div>
  );
};

export default VirtualTourDetail1;
