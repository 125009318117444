import * as React from "react";
import "./_Terms.scss";
import { useTranslation } from 'react-i18next';

function Terms() {
  const { t} = useTranslation();

  return (
    <div className="container-wrapper terms-wrapper">
      <div className="container container-gap terms-comp">
        <section>
          <h2>{t('Terms.17')}</h2>
          <p>{t('Terms.18')}</p>
        </section>
        <section>
          <h4>{t('Terms.19')}</h4>
          <p>{t('Terms.20')}</p>
        </section>
        <section>
          <h4>{t('Terms.21')}</h4>
          <p>{t('Terms.22')}</p>
        </section>
        <section>
          <h4>{t('Terms.23')}</h4>
          <p>{t('Terms.24')}</p>
        </section>
        <section>
          <h4>{t('Terms.25')}</h4>
          <p>{t('Terms.26')}</p>
        </section>
        <section>
          <h4>{t('Terms.27')}</h4>
          <p>{t('Terms.28')}</p>
        </section>
        <section>
          <h4>{t('Terms.29')}</h4>
          <p>{t('Terms.30')}</p>
        </section>
      </div>
    </div>
  );
}

export default Terms;
