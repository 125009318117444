import "./_AboutUsDetail.scss";
import img1 from "../../img/About-bg2.png";
import { useTranslation } from "react-i18next";
import { BsCheck2Square } from "react-icons/bs";
import Fade from "react-reveal/Fade";

export default function HomeAbout() {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper about-us-wrapper">
      <div className="container container-gap about-us">
        <Fade left cascade distance="10%">
          <div className="left">
            <h3>
              <span>{t("AboutUsDetail.0")}</span>
            </h3>
            <h4 className="detail-title-2">{t("AboutUsDetail.1")}</h4>
            <p>{t("AboutUsDetail.2")}</p>
            <p className="bp-text"> {t("AboutUsDetail.3")}</p>
          </div>
        </Fade>
        <Fade right distance="10%">
          <div className="right">
            <div className="img-wrapper">
              <img className="img" src={img1} alt="about" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
