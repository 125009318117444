import "./_NewsHome.scss";
import { NavLink } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";

export default function NewsCard(props) {

  const {t} = useTranslation()

  return (
    <div className="news-card">
      <div className="img-wrapper">
        <img src={props.url} alt="news" />
      </div>
      <div className="content">
        {/* <div className="time">{props.time}</div> */}
        <div className="title">{props.title}</div>
        <div className="text">{props.text}</div>
        <NavLink to={props.link}>
          <div className="nav-btn-news">
            <span>{ t('button.1')}</span>
            <HiOutlineArrowRight />
          </div>
        </NavLink>
      </div>
    </div>
  );
}
