import React from "react";
import "./VirtualtourDetail2.scss";
import PoleVirtualDetail from "../VirtualTour/PoleVirtualDetail.png";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const VirtualTourDetail2 = () => {
  return (
    <div>
      <div className="virtual-detail-close">
        <Link to="/Virtual">
          <FaTimes size="24px" />
        </Link>
      </div>
      <div className="virtual-detail-container">
        <div className="virtual-detail-wrapper">
          <div className="virtual-detail-img1">
            <img src={PoleVirtualDetail} alt="Prefab Production" />
          </div>
          <div className="virtual-detail-content">
            <h2>Campo de produção das casas pré-fabricadas:</h2>
            <p>
              Existem quatro principais oficinas para a produção de casas
              pré-fabricadas, que são a betonagem, encaixe de ferros de aço,
              cura a vapor de alta temperatura e por fim a desmoldagem das casas
              dentro dos moldes. As casas pré-fabricadas com resitência de betão
              C30, têm as seguintes dimensões 6500mm de comprimento, 3300mm de
              largura e 3000mm de altura.Com inumeras vantagens, estas casas
              oferecem uma personalização, integração na produção e decoração ao
              gosto do cliente.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualTourDetail2;
