import React from "react";
import "./ProductCard.scss";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { NavLink } from "react-router-dom";

const Card = (props) => {
  return (
    <div>
      <div className="prodcard-container">
        <div className="prodcard-img">
          <div className="card-img-zoom">
            <img src={props.img} alt="" />
          </div>
          <div className="prodcard-icon">
            <span>
              <img src={props.icon} alt="" />
            </span>
          </div>
        </div>
        <div className="prodcard-content ">
          <h4>{props.title}</h4>
          <div className="prodcard-text">
            <div className="p2">{props.text}</div>
            <NavLink to={props.link} className="prodcard-btn">
              <HiOutlineArrowSmRight size={20} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
