import "./_Leaders.scss";
import img1 from "../../img/image1.jpg";
import img2 from "../../img/image2.jpg";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
export default function Leaders() {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper leaders-wrapper">
      <div className="container container-gap leaders">
        <Fade bottom distance="10%">
          <div className="top">
            <h2>{t("Leaders.1")}</h2>
            <p>{t("Leaders.2")}</p>
          </div>
        </Fade>
        <div className="rows-wrapper">
          <div className="row row1">
            <Fade left distance="10%">
              <div className="img-wrapper">
                <div className="img">
                  <img src={img1} alt="" />
                </div>
                <div className="card card1">
                  <blockquote>{t("Leaders.speech1")}</blockquote>
                </div>
              </div>
            </Fade>
            <Fade right distance="10%">
              <div className="l-content">
                <h5>
                  <span className="l-bar"></span>
                  {t("Leaders.4")}
                </h5>
                <h3>{t("Leaders.3")}</h3>
                <p>{t("Leaders.5")}</p>
              </div>
            </Fade>
          </div>

          <div className="row row2">
            <Fade left distance="10%">
              <div className="l-content">
                <h5>
                  <span className="l-bar"></span>
                  {t("Leaders.7")}
                </h5>
                <h3>{t("Leaders.6")}</h3>
                <p>{t("Leaders.8")}</p>
              </div>
            </Fade>
            <Fade right distance="10%">
              <div className="img-wrapper2">
                <div className="img">
                  <img src={img2} alt="" />
                </div>
                <div className="card card2">
                  <blockquote>{t("Leaders.speech2")}</blockquote>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
