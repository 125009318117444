import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import News from "./pages/News";
import Project from "./pages/Project";
import Qualification from "./pages/Qualification";
import Team from "./pages/Team";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Virtual from "./pages/Vertual";
import Vt from "./pages/Vt";
import VirtualTourDetail1 from "./pages/subpages/VirtualTourDetail1";
import VirtualTourDetail2 from "./pages/subpages/VirtualTourDetail2";
import VirtualTourDetail3 from "./pages/subpages/VirtualTourDetail3";
import VirtualTourDetail4 from "./pages/subpages/VirtualTourDetail4";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NewsDetails from "./pages/NewsDetails";
import { useEffect } from "react";
import Product from "./pages/Product";
import ProductCatagory from "./pages/ProductCatagory";
import ProductDetail from "./pages/ProductDetails";
import ProjectDetails from "./pages/subpages/ProjectDetails";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Project" element={<Project />} />
        <Route path="/Certifications" element={<Qualification />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/News" element={<News />} />
        <Route path="/News/:pid" element={<NewsDetails />} />
        <Route path="/Project/:pid" element={<ProjectDetails/>} />
        <Route path="/products/:pid/" element={<Product />} />
        <Route path="/products/:pid/:sid" element={<ProductCatagory />} />
        <Route path="/products/details/:pid/:sid" element={<ProductDetail />} />
        <Route path="/Virtual" element={<Virtual />} />
        <Route path="/Vt" element={<Vt />} />
        <Route path="/VirtualTourDetail1" element={<VirtualTourDetail1 />} />
        <Route path="/VirtualTourDetail2" element={<VirtualTourDetail2 />} />
        <Route path="/VirtualTourDetail3" element={<VirtualTourDetail3 />} />
        <Route path="/VirtualTourDetail4" element={<VirtualTourDetail4 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
