import React from "react";
import VirtualtourDetail2 from "../../components/VirtualTour/VirtualtourDetail2";

const VirtualTourDetail2 = () => {
  return (
    <div>
      <VirtualtourDetail2 />
    </div>
  );
};

export default VirtualTourDetail2;
