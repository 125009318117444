import { BsPlusSquare} from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Card(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="custom-card" onClick={() => {
            navigate(props.url);
            window.scrollTo(0, 0)
        }}>
            <div className="card-img--box"><img className="card--img" src={props.img} alt={props.alt} />
            <div className="card-img--overlay"></div>
            </div>
            <h5 className="card--name">{props.name}</h5>
            <div className="card-btn-wrapper">
                <div className="card-btn-overlay"></div>
                <button className="card--btn" ><span className="card-btn-icon"><BsPlusSquare  /></span> <h5>{t('buttons.btn1')}</h5></button>
            </div>
        </div>
    )
}