import React from "react";
import "./_ImageSlider.scss";
import { MdContactPhone } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import SliderImage from "../../News/SliderImage";

function ImageSlider(props) {
  const navigate = useNavigate();

  const makeActive = () => {
    let activeImages = document.getElementsByClassName("active");
    let thumbnails = document.getElementsByClassName("thumbnail");
    for (let i = 0; i < thumbnails.length; i++) {
      thumbnails[i].addEventListener("mouseover", function () {
        if (activeImages.length > 0) {
          activeImages[0].classList.remove("active");
        }
        this.classList.add("active");
        document.getElementById("featureds").src = this.src;
      });
    }
  };

  const slideLeft = () => {
    document.getElementById("sliderrs").scrollLeft -= 180;
  };

  const slideRight = () => {
    document.getElementById("sliderrs").scrollLeft += 180;
  };

  return (
    <div className="container-wrapper">
      <div className="container container-gap product-details-container">
      <div className="gallery-slider">
      <SliderImage newsImage = {props.imgs} />
        </div>
        <div className="prod-detail-description">
          <div>
            <h4 className="prod-detail-nav-wrapper">
              {" "}
              <a
                onClick={() => {
                  navigate(props.linkMainProd);
                  window.scrollTo(0, 300);
                }}
                className="prod-detail-nav"
              >
                {props.navProd}
              </a>
              <MdChevronRight size={20} style={{ fill: "#858585" }} />{" "}
              <a href="" className="prod-detail-nav prod-detail-nav2">
                {props.detailTitle}
              </a>
            </h4>
          </div>
          <h2>{props.detailTitle}</h2>
          <div className="prefab-intro-icons">

              <div className="prefab-icon-title">
                <div className="prefab-icon-wrap">
                  <img src={props.icons[0]} />
                  <span className="prefap-spec">{props.specs[0].value}</span>
                </div>
                <h6 className="prefab-spec-title">{props.specs[0].title}</h6>
              </div>
              <div className="prefab-icon-title">
                <div className="prefab-icon-wrap icon-bed">
                  <img src={props.icons[1]} />
                  <span className="prefap-spec">{props.specs[1].value}</span>
                </div>
                <h6 className="prefab-spec-title"> {props.specs[1].title}</h6>
              </div>
 
 
              <div className="prefab-icon-title">
                <div className="prefab-icon-wrap">
                  <img src={props.icons[2]} />
                  <span className="prefap-spec">{props.specs[2].value}</span>
                </div>
                <h6 className="prefab-spec-title">{props.specs[2].title}</h6>
              </div>
              <div className="prefab-icon-title">
                <div className="prefab-icon-wrap">
                  <img className="prefab-icon-img4" src={props.icons[3]} />
                  <span className="prefap-spec">{props.specs[3].value}</span>
                </div>
                <h6 className="prefab-spec-title">{props.specs[3].title}</h6>
              </div>
            </div>


          <p className="prod-detail-desc" >{props.detailIntro}</p>
          {/* <div className="prod-main-btn">
              <span>{t('buttons.btn2')}</span>
              <MdContactPhone size={23} color="white" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
