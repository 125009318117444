import React from "react";
import "./_Banner.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
const Banner = (props) => {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper banner-wrapper"
    style={{
      backgroundImage: "url("+ props.slides.url +")",
      backgroundPosition:"center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
    >
      <div className="banner-common p-0 #banner-mgt" >
        <div className="banner-overlay"></div>
          <div className=" banner-title  ">
              <Fade left distance="10%">
                <div className="banner-header container">
                  <h1 className="banner-title-span ">{props.slides.title}</h1>
                  <h4 className="banner-text-span ">{props.slides.body}</h4>
                </div>
              </Fade>
          </div>
      </div>
     
    </div>
  );
};

export default Banner;
