export const ProductData = [
    {
        main: 'productList.product1.name',
        list: [ 'productList.product1.catagory.cat1', 'productList.product1.catagory.cat2', 'productList.product1.catagory.cat3', 'productList.product1.catagory.cat4', 'productList.product1.catagory.cat5' ],
        url: '/products/gypsum-board',
        listUrl: ['/products/gypsum-board/ordinary', '/products/gypsum-board/moisture', '/products/gypsum-board/fire', '/products/gypsum-board/water', '/products/gypsum-board/coated'],
        sub: true,
        subpages: ['/products/details/gypsum-board/ordinary', '/products/details/gypsum-board/moisture', '/products/details/gypsum-board/fire', '/products/details/gypsum-board/water', '/products/details/gypsum-board/coated']
    },
    {
        main: 'productList.product2.name',
        list: ['productList.product2.catagory.cat1', 'productList.product2.catagory.cat2', 'productList.product2.catagory.cat3'],
        url: '/products/prefab',
        listUrl: ['/products/prefab/resedential', '/products/prefab/school', '/products/prefab/apartment'],
        sub: true,
        subpages: ['/products/details/prefab/house01', '/products/details/prefab/house02', '/products/details/prefab/house03', '/products/details/prefab/house04', '/products/details/prefab/house05', '/products/details/prefab/house06', '/products/details/prefab/school01', '/products/details/prefab/apartment01' ]
    },
    {
        main: 'productList.product5.name',
        list: ['productList.product5.catagory.cat1', 'productList.product5.catagory.cat2', 'productList.product5.catagory.cat3','productList.product5.catagory.cat4'],
        listUrl: ['/products/brick/hollow', '/products/brick/solid', '/products/brick/pave', '/products/brick/curb'],
        url: '/products/brick',
        sub: true,
        subpages: ['/products/details/brick/detail']
    },
    {
        main: 'productList.product4.name',
        sub: false,
        url: '/products/pipe',
        listUrl: [],
        list: [],
        subpages: ['/products/details/pipe/detail']
    },
    {
        main: 'productList.product3.name',
        sub: false,
        url: '/products/pole',
        listUrl: [],
        list: [],
        subpages: ['/products/details/pole/detail']
    }
]

export const Products = [
    {
        img: "/prefab-house-product-img1.png",
        alt: "Prefabricated House 01",
        name: "productCards.prefab.product1.name",
        url: "/products/details/prefab/house01",
        catagory: "resedential",
        product: "prefab"
    },
    {
        img: "/prefab-house-product-img2.png",
        alt: "Prefabricated House 02",
        name: "productCards.prefab.product2.name",
        url: "/products/details/prefab/house02",
        catagory: "resedential",
        product: "prefab"
    },
    {
        img: "/prefab-house-product-img3.png",
        alt: "Prefabricated House 03",
        name: "productCards.prefab.product3.name",
        url: "/products/details/prefab/house02",
        catagory: "resedential",
        product: "prefab"
    },
    {
        img: "/prefab-house-product-img4.png",
        alt: "Prefabricated House 04",
        name: "productCards.prefab.product4.name",
        url: "/products/details/prefab/house04",
        catagory: "resedential",
        product: "prefab"
    },
    {
        img: "/prefab-house-product-img5.png",
        alt: "Prefabricated House 05",
        name: "productCards.prefab.product5.name",
        url: "/products/details/prefab/house05",
        catagory: "resedential",
        product: "prefab"
    },
    {
        img: "/prefab-house-product-img6.png",
        alt: "Prefabricated House 06",
        name: "productCards.prefab.product6.name",
        url: "/products/details/prefab/house06",
        catagory: "resedential",
        product: "prefab"
    },
    {
        img: "/prefab-school-product-img1.png",
        alt: "Prefabricated House 07",
        name: "productCards.prefab.product7.name",
        url: "/products/details/prefab/school01",
        catagory: "school",
        product: "prefab"
    },
    {
        img: "/prefab-apartment-product-img1.png",
        alt: "Prefabricated House 08",
        name: "productCards.prefab.product8.name",
        url: "/products/details/prefab/apartment01",
        catagory: "apartment",
        product: "prefab"
    },
    {
        img: "/brick-product-img1.png",
        alt: "Hollow Bricks",
        name: "productCards.brick.product1.name",
        url: "/products/details/brick/detail",
        catagory: "hollow",
        sub: "hollow",
        product: "brick"
    },
    {
        img: "/brick-product-img2.png",
        alt: "Solid Bricks",
        name: "productCards.brick.product2.name",
        url: "/products/details/brick/detail",
        catagory: "solid",
        sub: "solid",
        product: "brick"
    },
    {
        img: "/brick-product-img3.png",
        alt: "Pave type block",
        name: "productCards.brick.product3.name",
        url: "/products/details/brick/detail",
        catagory: "pave",
        sub: "pave",
        product: "brick"
    },
    {
        img: "/brick-product-img4.png",
        alt: "Cement curb",
        name: "productCards.brick.product4.name",
        url: "/products/details/brick/detail",
        catagory: "curb",
        sub: "curb",
        product: "brick"
    },
    {
        img: "/pipe-product-img1.png",
        alt: "Concerte Shackles",
        name: "productCards.pipe.product1.name",
        url: "/products/details/pipe/detail",
        catagory: "pipe",
        product: "pipe"
    },
    {
        img: "/pole-product-img1.png",
        alt: "Electric Poles",
        name: "productCards.pole.product1.name",
        url: "/products/details/pole/detail",
        catagory: "pole",
        product: "pole"
    },
    {
        img: "/gypsum-product-img1.png",
        alt: "Ordinary Drywall",
        name: "productCards.gypsum.product1.name",
        url: "/products/details/gypsum-board/ordinary",
        catagory: "ordinary",
        product: "gypsum-board"
    },
    {
        img: "/gypsum-product-img2.png",
        alt: "Moisture-proof Drywall",
        name: "productCards.gypsum.product2.name",
        url: "/products/details/gypsum-board/moisture",
        catagory: "moisture",
        product: "gypsum-board"
    },
    {
        img: "/gypsum-product-img3.png",
        alt: "Fire-resistant Drywall",
        name: "productCards.gypsum.product3.name",
        url: "/products/details/gypsum-board/fire",
        catagory: "fire",
        product: "gypsum-board"
    },
    {
        img: "/gypsum-product-img4.png",
        alt: "Water-resistant Drywall",
        name: "productCards.gypsum.product4.name",
        url: "/products/details/gypsum-board/water",
        catagory: "water",
        product: "gypsum-board"
    },
    {
        img: "/gypsum-product-img5.png",
        alt: "Coated Plasterboard",
        name: "productCards.gypsum.product5.name",
        url: "/products/details/gypsum-board/coated",
        catagory: "coated",
        product: "gypsum-board"
    }
]

