import React from "react";
import Banner from "../components/Banner/Banner";
import AboutUsDetail from "../components/AboutUsDetail/AboutUsDetail";
import VisionMission from "../components/VisionMission/VisionMission";
import CoreValues from "../components/CoreValues/CoreValues";
import VirtualAbout from "../components/AboutVitual/VirtualAbout";
import { useTranslation } from "react-i18next";
import AboutPromo from "../components/AboutPromo/AboutPromo";

function About() {
  const { t } = useTranslation();
  const slides = [
    {
      url: "AboutBanner.jpg",
      title: t("nav.2")
    }
  ];
  return (
    <>
      <Banner slides={slides[0]} />
      <AboutUsDetail />
      <VisionMission />
      <CoreValues />
      <AboutPromo />
      <VirtualAbout />
    </>
  );
}

export default About;
