import React from "react";
import "./Footer.scss";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="container-wrapper footer-continer ">
        <div className="container  footer-wrapper container-gap">
          <div className="footer-col-wrapper">
            <div className="footer-1">
              <div className="column-footer column-logo">
                <h3 className="footer-logo-txt">
                  <span className="footer-logo"> {t(`Footer.col1.logoTitle`)} </span>  {t(`Footer.col1.logoTitleSpan`)}
                </h3>
                <hr className="footer-header-hr hr-logo" />
                <div className="footer-logo-p">
                  <p>
                  {t(`Footer.col1.text`)}
                  </p>
                </div>
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/anjiaarquitecture/?_rdc=1&_rdr"
                    target="_blank"
                  >
                    <div className="fb icons">
                      <FaFacebookF size={14} />
                    </div>
                  </a>
                  <a
                    href="#"
                  >
                    <div className="whatsapp icons">
                      <IoLogoWhatsapp size={14} />
                    </div>
                  </a>
                  <a href="https://www.youtube.com/watch?v=luQWUenqFjQ&list=PL5R6lmEL79Xx4ECWY2526He3SsEsJNfJx&pp=gAQBiAQB"
                    target="_blank">
                    <div className="youtube icons">
                      <FaYoutube size={14} />
                    </div>
                  </a>
                </div>
              </div>
              <div className="column-footer column-menu">
                <div className="footer-header">
                  <h3 className="footer-headers">
                    {t(`Footer.col1.linksHeader`)}
                  </h3>
                  <hr className="footer-header-hr" />
                </div>
                <div>
                  <ul className="menu-links-wrapper">
                    <li className="menu-links">
                      <NavLink to="/"> {t(`Footer.col1.linkHome`)}</NavLink>
                    </li>
                    <li className="menu-links">
                      <NavLink to="/About">{t(`Footer.col1.linkAbout`)}</NavLink>
                    </li>
                    <li className="menu-links">
                      <NavLink to="/products/gypsum-board">{t(`Footer.col1.linkProd`)}</NavLink>
                    </li>
                    <li className="menu-links">
                      <NavLink to="/Project">{t(`Footer.col1.linkProj`)}</NavLink>
                    </li>
                    <li className="menu-links">
                      <NavLink to="/News">{t(`Footer.col1.linkNews`)}</NavLink>
                    </li>
                    {/* <li className="menu-links">
                      <a href="">{t(`Footer.col1.linkFAQ`)}</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-2">
              <div className="column-footer column-contact">
                <h3 className="footer-headers">{t(`Footer.col2.linksContact`)}</h3>
                <hr className="footer-header-hr" />
                <div>
                  <ul className="footer-contact">
                    <li className="phone">
                      <a href="#">(+258) 864 056 883</a>
                    </li>
                    <li className="address">
                    {t(`Footer.col2.address`)}
                    </li>
                    <li className="address email-add ">
                      anjiamoz@westholdinggroup.com
                    </li>
                  </ul>
                  <ul className="footer-contact gypsum-address">
                    <li className="phone">
                      <a href="#">(+258) 875 042 694</a>
                    </li>
                    <li className="address email-add ">
                      zhaoyi@westholdinggroup.com
                    </li>
                    <p className="gypsum-txt">{t(`Footer.col2.gypsumSmall`)}</p>
                  </ul>
                </div>
              </div>
              <div className="column-footer col-sister-comp">
                <h3 className="footer-headers">{t(`Footer.col3.linkHelp`)}</h3>
                <hr className="footer-header-hr" />
                <div className="sis-comp-links">
                  <p>{t(`Footer.col3.description`)}</p>
                  <NavLink to="/Contact" className="footer-contact-btn ">
                    <span>{t(`Footer.col3.btn`)}</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-wrapper footer-continer">
        <div className="container">
          <div className="copyright-wrapper">
            <div className="copy-right">
            {t(`Footer.bottom.copyRight`)}
            </div>
            <div className="terms">
              <div>
                <NavLink to="/Terms">
                  <p>{t(`Footer.bottom.term`)}</p>
                </NavLink>
              </div>
              <div>
                <NavLink to="/Privacy">
                  <p>{t(`Footer.bottom.privacy`)}</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;