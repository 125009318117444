import "./_HomeVideoGallery.scss";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import {useState} from "react"
import Fade from 'react-reveal/Fade';

export default function HomeVideoGallery() {
  const { t } = useTranslation()
  const currentVideo = useRef()
  const video = [
    {
      id:"1",
      title: t('video.1.title'),
      date: t('video.1.date'),
      src: "https://www.youtube.com/embed/luQWUenqFjQ",
      url: "/video-img1.png"
    },
    {
      id:"2",
      title: t('video.2.title'),
      date: t('video.2.date'),
      src: "https://www.youtube.com/embed/tH4lnvM-9LE",
      url: "/video-img2.png"
    },
    {
      id:"3",
      title: t('video.3.title'),
      date: t('video.3.date'),
      src: "https://www.youtube.com/embed/uIZ3IZBNr-k",
      url: "/video-img3.png"
    },
    {
      id:"4",
      title: t('video.4.title'),
      date: t('video.4.date'),
      src: "https://www.youtube.com/embed/qmo9Ig7nbPw",
      url: "/video-img4.png"
    },
    {
      id:"5",
      title: t('video.5.title'),
      date: t('video.5.date'),
      src: "vid1.mp4",
      url: "/video-img5.png"
    },
    {
      id:"6",
      title: t('video.6.title'),
      date: t('video.6.date'),
      src: "vid2.mp4",
      url: "/video-img6.png"
    },
    {
      id:"7",
      title: t('video.7.title'),
      date: t('video.7.date'),
      src: "vid3.mp4",
      url: "/video-img7.png"
    },
    {
      id:"8",
      title: t('video.8.title'),
      date: t('video.8.date'),
      src: "vid4.mp4",
      url: "/video-img8.png"
    },
    {
      id:"9",
      title: t('video.9.title'),
      date: t('video.9.date'),
      src: "video1.mp4",
      url: "/video-img9.png"
    },
    {
      id:"10",
      title: t('video.10.title'),
      date: t('video.10.date'),
      src: "video2.mp4",
      url: "/video-img10.png"
    },
    {
      id:"11",
      title: t('video.11.title'),
      date: t('video.11.date'),
      src: "video3.mp4",
      url: "/video-img11.png"
    },
    {
      id:"12",
      title: t('video.12.title'),
      date: t('video.12.date'),
      src: "video4.mp4",
      url: "/video-img12.png"
    },
    {
      id:"13",
      title: t('video.13.title'),
      date: t('video.13.date'),
      src: "video5.mp4",
      url: "/video-img13.png"
    },
    {
      id:"14",
      title: t('video.14.title'),
      date: t('video.14.date'),
      src: "video6.mp4",
      url: "/video-img14.png"
    },
    {
      id:"15",
      title: t('video.15.title'),
      date: t('video.15.date'),
      src: "video7.mp4",
      url: "/video-img15.png"
    }
  ];

  let mainVideo = document.getElementById("iframe")
  const [current, setCurrent] = useState(0)

  return (
    <div className="container-wrapper video-gallery-wrapper">
      <div className="container video-gallery container-gap">
        <Fade bottom distance="10%">
          <div className="top">
            <h2>{t('video.title1')} </h2>
          </div>
        </Fade>
        <div className="bottom">
          <div className="left">
            <iframe
              id="iframe"
              ref={currentVideo}
              src={video[current].src}
              title="Anjia Architecture Mozambique Introduction."
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"   
            ></iframe>
          </div>
          <div className="right-wrapper">
            <h3>{t('video.title')}</h3>
            <div className="right">
              {video.map((data, idx) => {
                return (
                  <div
                    key={data.id}
                    className= {idx === current ? "row row-active" : "row"}
                    onClick={() => {
                      mainVideo.src = data.src;
                      mainVideo.title = data.title;
                      setCurrent(idx)
                      console.log(current)
                    }}
                  >
                    <div className="img-wrapper"><img src={data.url} alt="video" /></div>
                    <div className="video-content">
                      <h4>{data.title}</h4>
                      <p>{data.date}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
