import React from "react";
import "./Virtualtour.scss";
import { AiFillContainer, AiOutlineFileImage } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import AboutBannerBg from "../VirtualTour/AboutBannerBg.jpg";
import BrickToolTip from "../VirtualTour/BrickToolTip.jpg";
import CulvertToolTip from "../VirtualTour/CulvertToolTip.jpg";
import PoleToolTip from "../VirtualTour/PoleToolTip.jpg";
import PrefabToolTip from "../VirtualTour/PrefabToolTip.jpg";
import { NavLink, Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const virtual = () => {
  const tooltips = document.querySelectorAll(".all-tooltip .tooltip");
  const fullDiv = document.querySelector("section");
  const tooltipcontainer = document.querySelector(".tooltipcontainer");
  let timeoutId;
  window.addEventListener("resize", contentPosition);
  window.addEventListener("DOMContentLoaded", contentPosition);

  function contentPosition() {
    tooltips.forEach((tooltip) => {
      const pin = tooltip.querySelector(".pin");
      const content = tooltip.querySelector(".tooltip-content");
      const arrow = tooltip.querySelector(".arrow");
      const pinLeft = pin.offsetLeft;
      content.style.left = pin.offsetLeft - content.offsetWidth / 2 + "px";
      content.style.top = pin.offsetTop + 60 + "px";
      arrow.style.left =
        pin.offsetLeft - content.offsetLeft + pin.offsetWidth / 2 + "px";
      if (pinLeft + content.offsetWidth / 2 > fullDiv.offsetWidth) {
        const extraLeft =
          fullDiv.offsetWidth - (pinLeft + content.offsetWidth / 2);
        // console.log('right-conflict', tooltip)
        content.style.left =
          pinLeft - content.offsetWidth / 2 + extraLeft - 30 + "px";
        content.style.top = pin.offsetTop + 30 + "px";
      } else if (
        pin.offsetLeft + tooltipcontainer.offsetLeft <
        content.offsetWidth / 2
      ) {
        // console.log('left conflict', pin.offsetLeft)
        content.style.left = -tooltipcontainer.offsetLeft + "px";
        content.style.top = pin.offsetTop + 30 + "px";
      } else {
        content.style.left = pinLeft - content.offsetWidth / 2 + "px";
        content.style.top = pin.offsetTop + 30 + "px";
      }
      arrow.style.left =
        pinLeft - content.offsetLeft + pin.offsetWidth / 2 + "px";
    });
  }

  function testfun11() {
    const content1 = document.querySelector(".tooltip-1");
    clearTimeout(timeoutId);
    content1.classList.add("active");
  }
  function testfun12() {
    const content1 = document.querySelector(".tooltip-1");
    timeoutId = setTimeout(() => {
      if (!content1.classList.contains("content-hover")) {
        content1.classList.remove("active");
      }
    }, 500);
  }

  function testfun21() {
    const content2 = document.querySelector(".tooltip-2");
    content2.classList.add("active");
  }
  function testfun22() {
    const content2 = document.querySelector(".tooltip-2");
    timeoutId = setTimeout(() => {
      if (!content2.classList.contains("content-hover")) {
        content2.classList.remove("active");
      }
    }, 500);
  }

  function testfun31() {
    const content3 = document.querySelector(".tooltip-3");
    content3.classList.add("active");
  }
  function testfun32() {
    const content3 = document.querySelector(".tooltip-3");
    timeoutId = setTimeout(() => {
      if (!content3.classList.contains("content-hover")) {
        content3.classList.remove("active");
      }
    }, 500);
  }

  function testfun41() {
    const content4 = document.querySelector(".tooltip-4");
    content4.classList.add("active");
  }
  function testfun42() {
    const content4 = document.querySelector(".tooltip-4");
    timeoutId = setTimeout(() => {
      if (!content4.classList.contains("content-hover")) {
        content4.classList.remove("active");
      }
    }, 500);
  }

  // tooltips.forEach((tooltip) => {
  //   const pin = tooltip.querySelector(".pin");
  //   const content = tooltip.querySelector(".tooltip-content");
  //   pin.addEventListener("mouseover", () => {
  //     tooltip.classList.add("active");
  //   });
  //   pin.addEventListener("mouseleave", () => {
  //     timeoutId = setTimeout(() => {
  //       if (!tooltip.classList.contains("content-hover")) {
  //         tooltip.classList.remove("active");
  //       }
  //     }, 2000);
  //   });
  //   content.addEventListener("mouseover", () => {
  //     clearTimeout(timeoutId);
  //     tooltip.classList.add("active");
  //     tooltip.classList.add("content-hover");
  //   });
  //   content.addEventListener("mouseleave", () => {
  //     timeoutId = setTimeout(() => {
  //       tooltip.classList.remove("active");
  //       tooltip.classList.remove("content-hover");
  //     }, 2000);
  //   });
  // });

  return (
    <div>
      <div className="virtual-tour-topbar">
        <div className="virtual-tour-return">
          <Link to="/About" >
            <RiArrowGoBackFill size="30px" color="#fff" />
          </Link>
        </div>
      </div>
      <section>
        <div className="tooltipcontainer">
          {<img src={AboutBannerBg} alt="" />}
          <div className="all-tooltip">
            <div className=" tooltip tooltip-1">
              <div
                className="pin"
                onMouseOver={() => testfun11()}
                onMouseLeave={() => testfun12()}
              >
                <ion-icon name="add-circle"></ion-icon>
                <div className="tooltip-content">
                  <div className="arrow"></div>
                  <div className="content">
                    <h3>Campo de produção das casas pré-fabricadas</h3>
                  </div>
                  <div className="img">
                    <img src={PrefabToolTip} alt="" />
                  </div>
                  <div className="virtual-detail-btn">
                    <NavLink to="/VirtualTourDetail1" className="btn-custom ">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="tooltip tooltip-2">
              <div
                className="pin"
                onMouseOver={() => testfun21()}
                onMouseLeave={() => testfun22()}
              >
                <div className="tooltip-content">
                  <div className="arrow"></div>
                  <div className="content">
                    <h3>Campo de produção dos postes elétricos</h3>
                  </div>
                  <div className="img">
                    <img src={PoleToolTip} alt="" />
                  </div>
                  <div className="virtual-detail-btn">
                    <NavLink to="/VirtualTourDetail2" className="btn-custom ">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="tooltip tooltip-3">
              <div
                className="pin"
                onMouseOver={() => testfun31()}
                onMouseLeave={() => testfun32()}
              >
                <div className="tooltip-content">
                  <div className="arrow"></div>
                  <div className="content">
                    <h3>Campo de produção de manilhas de betào</h3>
                  </div>
                  <div className="img">
                    <img src={CulvertToolTip} alt="" />
                  </div>
                  <div className="virtual-detail-btn">
                    <NavLink to="/VirtualTourDetail3" className="btn-custom ">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="tooltip tooltip-4">
              <div
                className="pin"
                onMouseOver={() => testfun41()}
                onMouseLeave={() => testfun42()}
              >
                <div className="tooltip-content">
                  <div className="arrow"></div>
                  <div className="content">
                    <h3>Campo de produção de betão</h3>
                  </div>
                  <div className="img">
                    <img src={BrickToolTip} alt="" />
                  </div>
                  <div className="virtual-detail-btn">
                    <NavLink to="/VirtualTourDetail4" className=" btn-custom ">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default virtual;
