import React from "react";
import AboutVirtual from "../AboutVitual/AboutVirtual.jpg";
import "./VirtualAbout.scss";
import { Link } from "react-router-dom";
import { BsPlayFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const VirtualAbout = () => {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper container-gap">
      <div
        className="container virtual-about-container"
        id="virtual-home-section"
      >
        <Fade bottom distance="10%">
          <div className="virtual-headline">
            <div className="virtual-headline-title">
              <h2>{t(`Virtual.title`)}</h2>
              <p>
                {t(`Virtual.text`)}
              </p>
            </div>
            <div className="virtual-main-btn">
              <a href="/Virtual">
                <span>{t(`Virtual.btn`)}</span>
                <span className="v-icon">
                  <BsPlayFill size={24} />
                </span>
              </a>
            </div>
          </div>
        </Fade>
        <div className=" vertual-intro-about-img">
          <img src={AboutVirtual} />
        </div>
      </div>
    </div>
  );
};

export default VirtualAbout;
