import "../ProductDetailComponents/Table.scss";
import { useTranslation, Trans } from "react-i18next";

export default function Table1() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container-wrapper">
      <div className="container container-gap specification-table">
        <div className="product-spec">
          <h3>{t('productDetails.spec')}</h3>
          <hr className="prod-detail-hr-line" />
        </div>
        <div className="table-container">
          <table className="table-spec-1">
            <thead>
              <th>
                <p>{t("gypsumProductDetail.1")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.2")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.3")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.4")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.5")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.6")}</p>
              </th>
            </thead>
            <tbody>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>2000-3000</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>1200-1220</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>9.5mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>6,5</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>{t("gypsumProductDetail.7")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.6")}>
                  <p>EN520</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>2000-3000</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>1200-1220</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>12.5mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>8,2</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>{t("gypsumProductDetail.7")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.6")}>
                  <p>EN520</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>2000-3000</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>1200-1220</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>13mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>8,5</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>{t("gypsumProductDetail.7")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.6")}>
                  <p>EN520</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>2000-3000</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>1200-1220</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>15mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>9,4</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>{t("gypsumProductDetail.7")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.6")}>
                  <p>EN520</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>2000-3000</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>1200-1220</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>18mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>11,3</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>{t("gypsumProductDetail.7")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.6")}>
                  <p>EN520</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>2000-3000</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>1200-1220</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>20mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>12,6</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>{t("gypsumProductDetail.7")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.6")}>
                  <p>EN520</p>
                </td>
              </tr>
              {" "}
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
}
