import React from "react";
import "../App.css";
import "../components/Banner/_Banner.scss";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import Contact from "../components/Contact/Contact";
import { useTranslation, Trans } from "react-i18next";
import Form from "../components/Contact/Form";
import GoogleMap from "../components/Contact/GoogleMap";

function Contacts() {
  const { t, i18n } = useTranslation();
  const slides = [
    {
      url: "ContactBanner.jpg",
      title: t("banner.15"),
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      <div className="banner-main-container">
        <Banner slides={slides[0]} />
      </div>
      <Contact />
      <GoogleMap/>
      <Form />
      
    </>
  );
}

export default Contacts;
