import "../components/Banner/_Banner.scss";
import { useTranslation, Trans } from "react-i18next";
import Table1 from "../components/ProductDetailComponents/Table1";
import Table2 from "../components/ProductDetailComponents/Table2";
import ImageSlider from "../components/ProductDetailComponents/ImageSlider/ImageSlider";
import ProductChar from "../components/ProductDetailComponents/ProductChar/ProductChar";
import RelatedProducts from "../components/ProductDetailComponents/ProductRelated/RelatedProducts";
import { useParams } from "react-router-dom";
import Banner from "../components/Banner/Banner";
import "../components/products-styles.scss";
import "../components/ProductDetailComponents/ProductDetails.scss";
import { useLocation } from "react-router-dom";
import PrefabVideo from "../components/ProductDetailComponents/PrefabVideo";
import ImageSlider2 from "../components/ProductDetailComponents/ImageSlider/ImageSliderPrefab";
import PipeTable from "../components/ProductDetailComponents/PipeTable/Table";
import PoleTable from "../components/ProductDetailComponents/PoleTable/Table"
import BrickTable from "../components/ProductDetailComponents/BrickTable/Table"
export default function ProductDetail() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { pid, sid } = useParams();

  const slides = [
    {
      url: "/ProductBanner.jpg",
      title: t("nav.5"),
      body: t("banner.4")
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }
  ];

  const detailsData = [
    {
      slides: [
        "/GypsumStd1.jpg",
        "/GypsumStd1.jpg",
        "/GypsumStd2.jpg",
        "/GypsumStd3.jpg",
        "/GypsumStd4.jpg",
        "/GypsumStd1.jpg"
      ],
      icons: [],
      specs: [
        {
          title: "",
          value: ""
        }
      ],
      link: "/products/details/gypsum-board/ordinary",
      linkMainProd: "/products/gypsum-board/",
      nav1: t("productDetails.gypsum.title1"),
      nav2: t("productDetails.gypsum.title2"),
      name: t("gypsum.product1.name"),
      detail: t("gypsum.product1.detail"),

      chars: [
        t("gypsum.product1.characterstics.1"),
        t("gypsum.product1.characterstics.2"),
        t("gypsum.product1.characterstics.3"),
        t("gypsum.product1.characterstics.4")
        // t('gypsum.product1.characterstics.5'),
      ],
      remark: t("gypsum.product1.remark"),
      remarkText: t("gypsum.product1.remarkText"),
      related: [
        {
          link: "/products/details/gypsum-board/fire",
          img: "/FireResistRelated.png",
          name: t("productCards.gypsum.product3.name")
        },
        {
          link: "/products/details/gypsum-board/moisture",
          img: "/MoistureProofRelated.png",
          name: t("productCards.gypsum.product2.name")
        },
        {
          link: "/products/details/gypsum-board/water",
          img: "/WaterResistRelated.png",
          name: t("productCards.gypsum.product4.name")
        },
        {
          link: "/products/details/gypsum-board/coated",
          img: "/CoatedRelated.png",
          name: t("productCards.gypsum.product5.name")
        }
      ]
    },
    {
      slides: [
        "/GypsumHumid1.jpg",
        "/GypsumHumid1.jpg",
        "/GypsumHumid2.jpg",
        "/GypsumHumid3.jpg",
        "/GypsumHumid3.jpg",
        "/GypsumHumid3.jpg"
      ],
      icons: [],
      specs: [
        {
          title: "",
          value: ""
        }
      ],
      link: "/products/details/gypsum-board/moisture",
      linkMainProd: "/products/gypsum-board/",
      nav1: t("productDetails.gypsum.title1"),
      nav2: t("productDetails.gypsum.title3"),
      name: t("gypsum.product2.name"),
      detail: t("gypsum.product2.detail"),

      chars: [
        t("gypsum.product2.characterstics.1"),
        t("gypsum.product2.characterstics.2"),
        t("gypsum.product2.characterstics.3"),
        t("gypsum.product2.characterstics.4"),
        t("gypsum.product2.characterstics.5")
      ],
      remark: t("gypsum.product2.remark"),
      remarkText: t("gypsum.product2.remarkText"),
      related: [
        {
          link: "/products/details/gypsum-board/fire",
          img: "/FireResistRelated.png",
          name: t("productCards.gypsum.product3.name")
        },
        {
          link: "/products/details/gypsum-board/ordinary",
          img: "/StandardRelated.png",
          name: t("productCards.gypsum.product1.name")
        },
        {
          link: "/products/details/gypsum-board/water",
          img: "/WaterResistRelated.png",
          name: t("productCards.gypsum.product4.name")
        },
        {
          link: "/products/details/gypsum-board/coated",
          img: "/CoatedRelated.png",
          name: t("productCards.gypsum.product5.name")
        }
      ]
    },
    {
      slides: [
        "/GypsumFire1.jpg",
        "/GypsumFire1.jpg",
        "/GypsumFire2.jpg",
        "/GypsumFire3.jpg",
        "/GypsumFire2.jpg",
        "/GypsumFire3.jpg"
      ],
      icons: [],
      specs: [
        {
          title: "",
          value: ""
        }
      ],
      link: "/products/details/gypsum-board/fire",
      linkMainProd: "/products/gypsum-board/",
      nav1: t("productDetails.gypsum.title1"),
      nav2: t("productDetails.gypsum.title4"),
      name: t("gypsum.product3.name"),
      detail: t("gypsum.product3.detail"),

      chars: [
        t("gypsum.product3.characterstics.1"),
        t("gypsum.product3.characterstics.2"),
        t("gypsum.product3.characterstics.3"),
        t("gypsum.product3.characterstics.4"),
        t("gypsum.product3.characterstics.5")
      ],
      remark: t("gypsum.product3.remark"),
      remarkText: t("gypsum.product3.remarkText"),
      related: [
        {
          link: "/products/details/gypsum-board/ordinary",
          img: "/StandardRelated.png",
          name: t("productCards.gypsum.product1.name")
        },
        {
          link: "/products/details/gypsum-board/moisture",
          img: "/MoistureProofRelated.png",
          name: t("productCards.gypsum.product2.name")
        },
        {
          link: "/products/details/gypsum-board/water",
          img: "/WaterResistRelated.png",
          name: t("productCards.gypsum.product4.name")
        },
        {
          link: "/products/details/gypsum-board/coated",
          img: "/CoatedRelated.png",
          name: t("productCards.gypsum.product5.name")
        }
      ]
    },
    {
      slides: [
        "/WaterResistSlide.png",
        "/WaterResistSlide.png",
        "/WaterResistSlide.png",
        "/WaterResistSlide.png",
        "/WaterResistSlide.png",
        "/WaterResistSlide.png"
      ],
      icons: [],
      specs: [
        {
          title: "",
          value: ""
        }
      ],
      link: "/products/details/gypsum-board/water",
      linkMainProd: "/products/gypsum-board/",
      nav1: t("productDetails.gypsum.title1"),
      nav2: t("productDetails.gypsum.title5"),
      name: t("gypsum.product4.name"),
      detail: t("gypsum.product4.detail"),

      chars: [
        t("gypsum.product4.characterstics.1"),
        t("gypsum.product4.characterstics.2"),
        t("gypsum.product4.characterstics.3"),
        t("gypsum.product4.characterstics.4"),
        t("gypsum.product4.characterstics.5")
      ],
      remark: t("gypsum.product4.remark"),
      remarkText: t("gypsum.product4.remarkText"),
      related: [
        {
          link: "/products/details/gypsum-board/ordinary",
          img: "/StandardRelated.png",
          name: t("productCards.gypsum.product1.name")
        },
        {
          link: "/products/details/gypsum-board/moisture",
          img: "/MoistureProofRelated.png",
          name: t("productCards.gypsum.product2.name")
        },
        {
          link: "/products/details/gypsum-board/fire",
          img: "/FireResistRelated.png",
          name: t("productCards.gypsum.product3.name")
        },
        {
          link: "/products/details/gypsum-board/coated",
          img: "/CoatedRelated.png",
          name: t("productCards.gypsum.product5.name")
        }
      ]
    },
    {
      slides: [
        "/CoatedSlide.png",
        "/CoatedSlide2.png",
        "/CoatedSlide.png",
        "/CoatedSlide2.png",
        "/CoatedSlide.png",
        "/CoatedSlide2.png"
      ],
      icons: [],
      specs: [
        {
          title: "",
          value: ""
        }
      ],
      link: "/products/details/gypsum-board/coated",
      linkMainProd: "/products/gypsum-board/",
      nav1: t("productDetails.gypsum.title1"),
      nav2: t("productDetails.gypsum.title6"),
      name: t("gypsum.product5.name"),
      detail: t("gypsum.product5.detail"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/gypsum-board/ordinary",
          img: "/StandardRelated.png",
          name: t("productCards.gypsum.product1.name")
        },
        {
          link: "/products/details/gypsum-board/moisture",
          img: "/MoistureProofRelated.png",
          name: t("productCards.gypsum.product2.name")
        },
        {
          link: "/products/details/gypsum-board/fire",
          img: "/FireResistRelated.png",
          name: t("productCards.gypsum.product3.name")
        },
        {
          link: "/products/details/gypsum-board/water",
          img: "/WaterResistRelated.png",
          name: t("productCards.gypsum.product4.name")
        }
      ]
    },
    {
      slides: [
        "/PrefabHome1N.png",
        "/PrefabHome1N.png",
        "/PrefabHome1.1.PNG",
        "/PrefabHome1.3.PNG",
        "/PrefabHome1.4.PNG",
        "/PrefabHome1.5N.png"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "128.7m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: "2"
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: "1"
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: "2"
        }
      ],
      link: "/products/details/prefab/house01",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product1.name"),
      detail: t("prefab.product1.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        },
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHome2.PNG",
        "/PrefabHome2.PNG",
        "/PrefabHome2_1.PNG",
        "/PrefabHome2_2.PNG",
        "/PrefabHome2_3.PNG",
        "/PrefabHome2_5.png"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "221m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: "2"
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: "2"
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: "1"
        }
      ],
      link: "/products/details/prefab/house02",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product2.name"),
      detail: t("prefab.product2.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house06",
          img: "/PrefabRelated6.png",
          name: t("productDetails.prefab.spec.title1") + ": 703m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        },
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHome3.PNG",
        "/PrefabHome3.PNG",
        "/PrefabHome3_1.PNG",
        "/PrefabHome3_2.PNG",
        "/PrefabHome3_5.png",
        "/PrefabHome3_6.png"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "329m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: "5"
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: "7"
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: "2"
        }
      ],
      link: "/products/details/prefab/house03",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product3.name"),
      detail: t("prefab.product3.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house06",
          img: "/PrefabRelated6.png",
          name: t("productDetails.prefab.spec.title1") + ": 703m²"
        },
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        },
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHome4.PNG",
        "/PrefabHome4.PNG",
        "/PrefabHome4_1.PNG",
        "/PrefabHome4_3.PNG",
        "/PrefabHome4_5.png",
        "/PrefabHome4_6.png"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "443.8m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: "8"
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: "6"
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: "4"
        }
      ],
      link: "/products/details/prefab/house04",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product4.name"),
      detail: t("prefab.product4.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house06",
          img: "/PrefabRelated6.png",
          name: t("productDetails.prefab.spec.title1") + ": 703m²"
        },
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHome5.PNG",
        "/PrefabHome5.PNG",
        "/PrefabHome5_1.PNG",
        "/PrefabHome5_5.PNG",
        "/PrefabHome5_6.PNG",
        "/PrefabHome5_7.PNG"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "618m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: "8"
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: "7"
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: "2"
        }
      ],
      link: "/products/details/prefab/house05",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product5.name"),
      detail: t("prefab.product5.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house06",
          img: "/PrefabRelated6.png",
          name: t("productDetails.prefab.spec.title1") + ": 703m²"
        },
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHome6.PNG",
        "/PrefabHome6.PNG",
        "/PrefabHome6_1.PNG",
        "/PrefabHome6_4.PNG",
        "/PrefabHome6_5.PNG",
        "/PrefabHome6_7.PNG"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "703m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: "8"
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: "4"
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: "3"
        }
      ],
      link: "/products/details/prefab/house06",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product6.name"),
      detail: t("prefab.product6.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        },
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHomeSchool2.PNG",
        "/PrefabHomeSchool2.PNG",
        "/PrefabHomeSchool2_1.PNG",
        "/PrefabHomeSchool2_3.PNG",
        "/PrefabHomeSchool2_4.PNG",
        "/PrefabHomeSchool2_5.PNG"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "1118m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: ""
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: ""
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: ""
        }
      ],
      link: "/products/details/prefab/school01",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product7.name"),
      detail: t("prefab.product7.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        },
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        }
      ]
    },
    {
      slides: [
        "/PrefabHomeSchool1.PNG",
        "/PrefabHomeSchool1.PNG",
        "/PrefabHomeSchool1_1.PNG",
        "/PrefabHomeSchool1_2.PNG",
        "/PrefabHomeSchool1_3.PNG",
        "/PrefabHomeSchool1_4.PNG"
      ],
      icons: ["/Area.png", "/BedRoom2.png", "/BathRoom.png", "/LivingRoom.png"],
      specs: [
        {
          title: t("productDetails.prefab.spec.title1"),
          value: "1118m²"
        },
        {
          title: t("productDetails.prefab.spec.title2"),
          value: ""
        },
        {
          title: t("productDetails.prefab.spec.title3"),
          value: ""
        },
        {
          title: t("productDetails.prefab.spec.title4"),
          value: ""
        }
      ],
      link: "/products/details/prefab/apartment01",
      linkMainProd: "/products/prefab",
      nav1: t("productDetails.prefab.title1"),
      nav2: t("productDetails.prefab.title2"),
      name: t("prefab.product8.name"),
      detail: t("prefab.product8.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/prefab/house05",
          img: "/PrefabRelated5.png",
          name: t("productDetails.prefab.spec.title1") + ": 618m²"
        },
        {
          link: "/products/details/prefab/house02",
          img: "/PrefabRelated2.png",
          name: t("productDetails.prefab.spec.title1") + ": 221m²"
        },
        {
          link: "/products/details/prefab/house03",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.spec.title1") + ": 329m²"
        },
        {
          link: "/products/details/prefab/house04",
          img: "/PrefabRelated4.PNG",
          name: t("productDetails.prefab.spec.title1") + ": 443.8m²"
        }
      ]
    },
    {
      slides: [
        "/PipeSlide1.jpg",
        "/PipeSlide5.jpg",
        "/PipeSlide3.jpg",
        "/PipeSlide4.jpg",
        "/PipeSlide2.jpg"
      ],
      icons: [],
      specs: [],
      link: "/products/details/pipe/detail",
      linkMainProd: "/products/pipe",
      nav1: t("productDetails.pipe.title1"),
      nav2: t("productDetails.pipe.title2"),
      name: t("pipe.product1.name"),
      detail: t("pipe.product1.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/brick/detail",
          img: "/BricksRelated.jpg",
          name: t("productCards.brick.product1.name")
        },
        {
          link: "/products/gypsum-board",
          img: "/GypsumRelated.jpg",
          name: t("productDetails.gypsum.title1")
        },
        {
          link: "/products/details/pole/detail",
          img: "/PoleRelated.jpg",
          name: t("productCards.pole.product1.name")
        },
        {
          link: "/products/prefab",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.title1")
        }
      ]
    },
    {
      slides: [
        "/PoleSlider4.jpg",
        "/PoleSlider5.jpg",
        "/PoleSlider.jpg",
        "/PoleSlider6.jpg",
        "/PoleSlider7.png"
      ],
      icons: [],
      specs: [],
      link: "/products/details/pole/detail",
      linkMainProd: "/products/pole",
      nav1: t("productDetails.pole.title1"),
      nav2: t("productDetails.pole.title2"),
      name: t("pole.product1.name"),
      detail: t("pole.product1.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/brick",
          img: "/BricksRelated.jpg",
          name: t("productCards.brick.product1.name")
        },
        {
          link: "/products/gypsum-board",
          img: "/GypsumRelated.jpg",
          name: t("productDetails.gypsum.title1")
        },
        {
          link: "/products/details/pipe/detail",
          img: "/PipeRelated.jpg",
          name: t('productCards.pipe.product1.name')
        },
        {
          link: "/products/prefab",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.title1")
        }
      ]
    },
    {
      slides: [
        "/BricksSlider1.jpg",
        "/BricksSlider2.jpg",
        "/BricksSlider3.jpg",
        "/BricksSlider4.jpg",
        "/BricksSlider5.jpg",
        "/BricksSlider6.jpg"
      ],
      icons: [],
      specs: [],
      link: "/products/details/brick/detail",
      linkMainProd: "/products/brick",
      nav1: t("productDetails.brick.title1"),
      nav2: t("productDetails.brick.title2"),
      name: t("productCards.brick.product1.name"),
      detail: t("productCards.brick.product1.intro"),

      chars: [],
      remark: "",
      remarkText: "",
      related: [
        {
          link: "/products/details/pole/detail",
          img: "/PoleRelated.jpg",
          name: t("productCards.pole.product1.name")
        },
        {
          link: "/products/gypsum-board",
          img: "/GypsumRelated.jpg",
          name: t("productDetails.gypsum.title1")
        },
        {
          link: "/products/details/pipe/detail",
          img: "/PipeRelated.jpg",
          name: t('productCards.pipe.product1.name')
        },
        {
          link: "/products/prefab",
          img: "/PrefabRelated.png",
          name: t("productDetails.prefab.title1")
        }
      ]
    }
  ];

  let currentProductDetail = detailsData
    .filter(
      (filteredData) =>
        filteredData.link == "/products/details/" + pid + "/" + sid
    )
    .map((data) => {
      return data;
    });

  // console.log(currentProductDetail[0].slides);

  return (
    <div>
        <Banner slides={slides[0]} />

      {("/products/details/" + pid === "/products/details/gypsum-board" || 
        "/products/details/" + pid === "/products/details/pole" ||
        "/products/details/" + pid === "/products/details/pipe" ||
        "/products/details/" + pid === "/products/details/brick")
          && <ImageSlider
        imgs={currentProductDetail[0].slides}
        linkMainProd={currentProductDetail[0].linkMainProd}
        navProd={currentProductDetail[0].nav1}
        navDetail={currentProductDetail[0].nav2}
        detailTitle={currentProductDetail[0].name}
        detailIntro={currentProductDetail[0].detail}
        icons={currentProductDetail[0].icons}
        specs={currentProductDetail[0].specs}
        />}
      
      {("/products/details/" + pid === "/products/details/prefab")
          && <ImageSlider2
        imgs={currentProductDetail[0].slides}
        linkMainProd={currentProductDetail[0].linkMainProd}
        navProd={currentProductDetail[0].nav1}
        navDetail={currentProductDetail[0].nav2}
        detailTitle={currentProductDetail[0].name}
        detailIntro={currentProductDetail[0].detail}
        icons={currentProductDetail[0].icons}
        specs={currentProductDetail[0].specs}
      />}


      {"/products/details/" + pid === "/products/details/gypsum-board" &&
        "/products/details/gypsum-board/" + sid !==
          "/products/details/gypsum-board/coated" && <Table1 />}

      {"/products/details/" + pid + "/" + sid ===
        "/products/details/pipe/detail" && <PipeTable />}
      
      {"/products/details/" + pid + "/" + sid ===
        "/products/details/pole/detail" && <PoleTable />}
      
      {"/products/details/" + pid + "/" + sid ===
        "/products/details/brick/detail" && <BrickTable />}

      {"/products/details/gypsum-board/" + sid ===
        "/products/details/gypsum-board/coated" && <Table2 />}

      {"/products/details/gypsum-board" + sid ===
        "/products/details/gypsum-board/coated" &&
        "/products/details/" + pid !== "/products/details/gypsum-board" && (
          <ProductChar
            chars={currentProductDetail[0].chars}
            remark={currentProductDetail[0].remark}
            remarkText={currentProductDetail[0].remarkText}
          />
        )}

      {"/products/details/" + pid === "/products/details/prefab" && (
        <PrefabVideo />
      )}

      <RelatedProducts related={currentProductDetail[0].related} />
    </div>
  );
}
