import React from "react";
import ProjectDetail from "../../components/ProjectDetail/ProjectDetail";
import { useTranslation, Trans } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import { useParams } from 'react-router-dom';

const ProjectDetails = (props) => {
  const { t, i18n } = useTranslation();
  const { pid } = useParams()
  
    
    console.log(pid)
    const pages = [
        "Office_Building_Project",
        "School_Building_Project"
    ]
  const slides = [
    {
      url: "/banner-project.jpg",
      title: t("nav.10"),
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  const newsData = [
    {
        title: t(`Project.Detail.title`),
        date:"",
        newsText: [
           ""
        ],
        newsImage: [
            "/OfficeProjectImg2.jpg",
            "/OfficeProjectImg3.png",
            "/OfficeProjectImg4.png",
            "/OfficeProjectImg5.png",
        ]
    },
    {
        title: t(`Project.Detail.title`),
        date:"",
        newsText: [
            ""
        ],
        newsImage: [
            "/ShoolProjectImg2.jpg",
            "/SchoolProjectImg3.jpg",
        ]
    }
    
]

const currentNews = newsData[pages.indexOf(pid)]
console.log(currentNews)

  return (
    <div>
      <div className="banner-main-container">
        <Banner slides={slides[0]} />
      </div>
      <ProjectDetail 
       headline={currentNews.title}
       date={currentNews.date}
       newsText={currentNews.newsText}
       newsImage={currentNews.newsImage}
       pid={pid}
       pages={pages}
       newsData={newsData}
      />
    </div>
  );
};

export default ProjectDetails;
