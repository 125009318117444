import React, { useState } from "react";
import "./_Qualification.scss";
import img1 from "./img1.jpeg";
import Certificate1 from "../../img/Certificate1.jpg"
import { useTranslation, Trans } from "react-i18next";
import ProjectCard from "../ProjectCases/ProjectCard";
import Fade from "react-reveal/Fade";



function Qualification(props) {
  const { t, i18n } = useTranslation();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="container-wrapper  ">
      <div className="container certificate-wrapper container-gap">
        <Fade bottom distance="10%">
          <div className="certification-header">
            <h2>{t(`Qualifications.header`)}</h2>
            <p>
            {t(`Qualifications.text`)}
            </p>
          </div>
        </Fade>
        <Fade bottom distance="10%">
          <div className="cert-card-wrapper proj-card-wrapper ">
            <a
              href="/Certificates/NM_ISO_9001_2015.pdf"
              target="_blank"
              className="proj-card-container"
            >
              <div className="card">
                <div className="img-wrapper">
                  <img src={Certificate1} alt="projects" />
                  <div className="overlay"></div>
                </div>
                <div className="bottom">
                  <div className="certificate-title">
                    <h4>{t(`Qualifications.cardTitle`)} </h4>
                  </div>
                  <div className="certificate-title">
                    <h5 className="project-text">NM ISO 9001:2015</h5>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default Qualification;
