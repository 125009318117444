import "./_WhyUsHome.scss";
import icon1 from "../../img/why-us-icon1.png";
import icon2 from "../../img/why-us-icon2.png";
import icon3 from "../../img/why-us-icon3.png";
import icon4 from "../../img/why-us-icon4.png";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

export default function WhyUsHome() {

  const {t} = useTranslation()

  return (
    <div className="container-wrapper why-us-wrapper">
      <div className="container container-gap why-us">
        <div className="whyus-content">
            <Fade bottom distance="10%">
              <div className="top">
                <div className="title2">{t('whyus.title')}</div>
              </div>
            </Fade>

            <div className="bottom">
              <Fade left cascade distance="10%"> 
                <div className="row">
                  <div className="box">
                    <img className="whyus-icon" src={icon1} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t('whyus.1.title')}</div>
                      <div className="box-text">
                      {t('whyus.1.text')}
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <img className="whyus-icon" src={icon2} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t('whyus.2.title')}</div>
                      <div className="box-text">
                      {t('whyus.2.text')}
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade left cascade distance="15%"> 
                <div className="row">
                  <div className="box">
                    <img className="whyus-icon" src={icon3} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t('whyus.3.title')}</div>
                      <div className="box-text">
                      {t('whyus.3.text')}
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <img className="whyus-icon" src={icon4} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t('whyus.4.title')}</div>
                      <div className="box-text">
                      {t('whyus.4.text')}
                      </div>
                    </div>
                  </div>
                            </div>
              </Fade>

            </div>
        </div>
      </div>
    </div>
  );
}
