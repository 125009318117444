import "./PageNumber.scss"
import { useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

export default function PageNumber(props) {
    
    const navigate = useNavigate();
    
  function next() {
    function getNextIdx() {
      if (props.pages.indexOf(props.pid) < (props.pages.length - 1)) {
        return (props.pages.indexOf(props.pid) + 1)
      } else {
        return (props.pages.length - 1)
      }
    }
    let nextIdx = getNextIdx()
    navigate(props.mainPage+ props.pages[nextIdx])
      }
    
    function prev() {
      function getPrevIdx() {
        if (props.pages.indexOf(props.pid) >= 1 ) {
          return (props.pages.indexOf(props.pid) - 1)
        } else {
          return 0
        }
      }
      let prevIdx = getPrevIdx()
      navigate(props.mainPage + props.pages[prevIdx])

      }
      console.log(props.pages)

    return (
        <div className="page-number-wrapper">
        <div className="page-number-box" onClick={prev}>
          <BsArrowLeft />
        </div>
          {props.newsData.map((data, idx) => {
            return (
              <div className={(props.pages.indexOf(props.pid) + 2 ) < idx || (props.pages.indexOf(props.pid) - 2 > idx ) ? "page-number-box-hide": "page-number-box-show"}>
                  <div className={(props.pages.indexOf(props.pid) === idx) ? "page-number-box page-number-box-active" : "page-number-box"}
                    onClick={() => {
                        navigate(props.mainPage + props.pages[idx])
                  }}
                    >
                        <span>{idx + 1}</span>
                    </div>
              </div>
              )
            })}
        <div className="page-number-box"  onClick={next}>
          <BsArrowRight />
        </div>
        </div>
    )
}