import React from "react";
import "../App.css";
import "../components/Banner/_Banner.scss";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import Qualification from "../components/Qualification/Qualification";
import { useTranslation, Trans } from 'react-i18next';

function Qualifications() {
  const { t, i18n } = useTranslation();
  const slides = [
    {
      url: "Certificate.jpg",
      title: t('banner.12'),
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      <div className="banner-main-container">
        <Banner slides={slides[0]} />
      </div>
      <Qualification />
      </>
  );
}

export default Qualifications;