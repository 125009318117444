import React from "react";
import "./VirtualtourDetail4.scss";
import BrickVirtualDetail from "../VirtualTour/BrickVirtualDetail.png";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const VirtualTourDetail4 = () => {
  return (
    <div>
      <div className="virtual-detail-close">
        <Link to="/Virtual">
          <FaTimes size="24px" />
        </Link>
      </div>
      <div className="virtual-detail-container">
        <div className="virtual-detail-wrapper">
          <div className="virtual-detail-img1">
            <img src={BrickVirtualDetail} alt="Prefab Production" />
          </div>
          <div className="virtual-detail-content">
            <h2>Campo de produção de betão:</h2>
            <p>
              Produzimos bloco 15 e 20, produzimos ainda bloquetes, bloco tipo
              pave e lancil de cimento com diversas especificações.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualTourDetail4;
