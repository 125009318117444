import "./NewsDetail.scss"; 
import { useEffect, useState } from "react";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";

export default function SliderImage(props) {
    const len = props.newsImage.length - 1;

    const [activeIndex, setActiveIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
      }, 4000);
      return () => clearInterval(interval);
    }, [activeIndex]);
    return (
        <div className="news-slide-image-wrapper">
            {props.newsImage.map((data, idx) => {
              return (
                <img key={idx}
                  className={
                    activeIndex === idx
                      ? "news-slide-image news-slide-image-active"
                      : "news-slide-image"
                  }
                  src={data}
                  alt=""
                />
              );
            })}
            <div className="left-right-btn">
              <div>
                <HiChevronLeft
                  size={26}
                  className="prev"
                  onClick={() =>
                    setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
                  }
                />
              </div>
              <div>
                <HiChevronRight
                  className="next"
                  size={26}
                  onClick={() =>
                    setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
                  }
                />
              </div>
            </div>
          </div>
    )
}