import React from "react";
import "../Table.scss";
import { useTranslation, Trans } from 'react-i18next';

const Table = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container-wrapper">
      <div className="container container-gap specification-table">
        <div className="product-spec">
        <h3>Specification</h3>
            <hr className="prod-detail-hr-line"/>
        </div>
        <table className="table-spec-1">
          <thead>
            <th><p>{t('ProductBrickDetail1.3')}</p></th>
            <th><p>{t('ProductBrickDetail1.4')}</p></th>
            <th><p>{t('ProductBrickDetail1.5')}</p></th>
            <th><p>{t('ProductBrickDetail1.6')}</p></th>
            <th><p>{t('ProductBrickDetail1.7')}</p></th>
            <th><p>{t('ProductBrickDetail1.8')}</p></th>
          </thead>
          <tbody>
            <tr>
              <td data-label="Especificações"><p>{t('ProductBrickDetail1.9')}</p></td>
              <td data-label="Bloco 15#"><p>388mm</p></td>
              <td data-label="Bloco 20#"><p>388mm</p></td>
              <td data-label="Bloquete"><p>238mm</p></td>
              <td data-label="Bloco Tipo Pave"><p>198mm</p></td>
              <td data-label="Lancil de Cimento"><p>500mm</p></td>
            </tr>
            <tr>
              <td data-label="Especificações"><p>{t('ProductBrickDetail1.10')}</p></td>
              <td data-label="Bloco 15#"><p>188mm</p></td>
              <td data-label="Bloco 20#"><p>188mm</p></td>
              <td data-label="Bloquete"><p>115mm</p></td>
              <td data-label="Bloco Tipo Pave"><p>98mm</p></td>
              <td data-label="Lancil de Cimento">100mm</td>
            </tr>
            <tr>
              <td data-label="Especificações"><p>{t('ProductBrickDetail1.11')}</p></td>
              <td data-label="Bloco 15#"><p>149mm</p></td>
              <td data-label="Bloco 20#"><p>190mm</p></td>
              <td data-label="Bloquete"><p>51mm</p></td>
              <td data-label="Bloco Tipo Pave"><p>60mm</p></td>
              <td data-label="Lancil de Cimento"><p>300mm</p></td>
            </tr>
            <tr>
              <td data-label="Especificações"><p>{t('ProductBrickDetail1.12')}</p></td>
              <td data-label="Bloco 15#"><p>6-10Mpa</p></td>
              <td data-label="Bloco 20#"><p>6-10Mpa</p></td>
              <td data-label="Bloquete"><p>6-10Mpa</p></td>
              <td data-label="Bloco Tipo Pave"><p>6-10Mpa</p></td>
              <td data-label="Lancil de Cimento"><p>6-10Mpa</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
