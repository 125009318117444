import React from "react";
import "./_VisionMission.scss";
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

function VisionMission() {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper vm-container">

      <div className="container container-gap vm">
        <Fade bottom distance="10%">
          <div className="top">
            <h2>{t('VisionMission.title1')}</h2>
            <p>{t('VisionMission.title2')}</p>
          </div>
        </Fade>
        <Fade bottom cascade distance="10%">
          <div className="vm-cards-container">
            {/* Mission Card */}
            <div className="vm-card-wrapper">
              <div className="vm-card">
                <div className="vm-card-content">
                  <h4><span className="v-bar"></span>{t('VisionMission.1')}</h4>
                  <p>{t('VisionMission.2')}</p>
                </div>
              </div>
            </div>
            {/* Vision Card */}
            <div className="vm-card-wrapper">
              <div className="vm-card">
                <div className="vm-card-content">
                  <h4><span className="v-bar"></span>{t('VisionMission.3')}</h4>
                  <p>{t('VisionMission.4')}</p>
                </div>
              </div>
            </div>
          </div>
        </Fade>

      </div>
    </div>
  );
}

export default VisionMission;
