import React from "react";
import "./_Contact.scss";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { BsBuildings } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";


const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper container-gap contact-add-map">
      <div className="container contact-container">
        <Fade bottom distance="10%">
          <div className="contact-header">
            <h2>{t(`contact.title`)}</h2>
            <p>{t(`contact.text`)}</p>
          </div>
        </Fade>
        <Fade bottom cascade distance="10%">
          <div className="contact-info">
            <div className="contact-card comp-address">
              <div className="contact-card-header">
                <div className="contact-icon">
                  <BsBuildings size={28} />
                </div>
                <div className="contact-card-title">
                  <h4>{t(`contact.addressTitle`)}</h4>
                  <span>{t(`contact.addressBottom`)}</span>
                </div>
              </div>
              <div className="factory-address">
                <h5>{t(`contact.address`)}</h5>
                <h5>{t(`contact.address2`)}</h5>
              </div>
            </div>
            <div className="contact-card phone">
              <div className=" contact-card-header">
                <div className="contact-icon">
                  <FiPhoneCall size={28} />
                </div>
                <div className="contact-card-title">
                  <h4>{t(`contact.phoneTitle`)}</h4>
                  <span>{t(`contact.phoneBottom`)}</span>
                </div>
              </div>
              <div className="p-number">
                <h5>{t(`contact.phone`)}</h5>
                <h5 className="gypsum-number">{t(`contact.phone2`)}</h5>
                <span>
                  <i>({t(`contact.phoneGypsum`)})</i>
                </span>
              </div>
            </div>
            <div className="contact-card email">
              <div className=" contact-card-header">
                <div className="contact-icon">
                  <MdOutlineMarkEmailRead size={28} />
                </div>
                <div className="contact-card-title">
                  <h4>{t(`contact.emailTitle`)}</h4>
                  <span>{t(`contact.emailBottom`)}</span>
                </div>
              </div>
              <div className="email-address">
                <h5>{t(`contact.email`)}</h5>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Contact;


