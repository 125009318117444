import "./_NewsMain.scss"; 
import NewsCard from "../NewsHome/NewsCard"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';


export default function NewsMain() {
  const {t} = useTranslation()

  const row1 = [
    {
      url: "news001-img1.jpg",
      time: "",
      title: t('NewsHome.5'),
      text: t('NewsHome.7'),
      link: "/News/visit-of-council-of-xai-xai",
    },
    {
      url: "news002-img1.jpg",
      time: t('NewsHome.9'),
      title: t('NewsHome.8'),
      text: t('NewsHome.10'),
      link: "/News/construction-signing-ceremony",
    },
    {
      url: "news003-img1.jpg",
      time: "",
      title: t('NewsHome.11'),
      text: t('NewsHome.12'),
      link: "/News/a-shared-vision-for-a-better-world",
    },
  ];
  return (
    <div className="home-news-wrapper container-wrapper">
      <div className="home-news container container-gap">
          <Fade bottom distance="10%">
            <div className="top">
              {/* <div className="title1">{t('NewsHome.0')}</div> */}
              <div className="title2">{t('NewsHome.1')}</div>
              <div className="text"></div>
            </div>
          </Fade>
        <div className="cards-wrapper">
          <div className="row">
            {row1.map((data, idx) => {
              return (
                  <NewsCard key={idx}
                    url={data.url}
                    title={data.title}
                    time={data.time}
                    text={data.text}
                    link={data.link}
                  />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
