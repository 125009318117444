import "./_HomeAbout.scss"
import img1 from "../../img/About-bg.png";
import { useTranslation } from "react-i18next";
import { BsCheck2Square } from "react-icons/bs"
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';

export default function HomeAbout() {

  const {t} = useTranslation()
    return (
        <div className="container-wrapper home-about-wrapper">
            <div className="container home-about">
                <Fade left cascade distance="10%">
                    <div className="left">
                        <h2>{t('HomeAbout.0')}</h2>
                        <p>{t('HomeAbout.4')}</p>
                        <p className="bp-text"> </p>
                        <div className="list-wrapper">
                            <div>
                                <p><span className="about-icon"><BsCheck2Square /></span><span>{t('HomeAbout.list.1')}</span></p>
                                <p><span className="about-icon"><BsCheck2Square /></span><span>{t('HomeAbout.list.2')}</span></p>
                            </div>
                            <div>
                                <p><span className="about-icon"><BsCheck2Square /></span><span>{t('HomeAbout.list.3')}</span></p>
                                <p><span className="about-icon"><BsCheck2Square /></span><span>{t('HomeAbout.list.4')}</span></p>
                            </div>
                        </div>
                        <NavLink to="/About"><div className="btn2">{t('button.4')}</div></NavLink>
                    </div>
                </Fade >
                <Fade right distance="10%">
                    <div className="right">
                        <div className="img-wrapper">
                        <img className="img" src={img1} alt="about" />
                        </div>
                    </div>
                </Fade>
            </div>
        </div> 
    )
}