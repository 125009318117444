import React from "react";

const GoogleMap = () => {
  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="contact-section-2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229176.66904948978!2d32.504901851610924!3d-26.167859718665113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee43f0d25e19e27%3A0xc1b81f96fd8e9da3!2sAnjia%20Arquitecture%20(Mo%C3%A7ambique)%2C%20Lda!5e0!3m2!1sen!2set!4v1668608592940!5m2!1sen!2set"></iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
