import React, {  useState} from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import anjiaNig from "../../img/AnjiaNig.png"
import anjiaNigGray from "../../img/AnjiaNig1.png"
import GLC from "../../img/GLClogo.png"
import glcGray from "../../img/GLClogo1.png"
import wihGray from "../../img/WIH Logo1.png"
import dugongo from "../../img/MDClogo.png"
import dugongoGray from "../../img/MDClogo1.png"
import wihlogo from "../../img/WIH Logo.png"
import redLogo from "../../img/RedacaciaLogo.png"
import redGray from "../../img/RedacaciaLogo1.png"
import { NavLink } from "react-router-dom";
import "./Partners.scss"
import {useTranslation} from "react-i18next"

// export default class PartnerSlider extends Component 

  const PartnerSlider = () =>{

    const {t } = useTranslation()
  
      const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay:true,
        speed: 4000,
        autoplaySpeed: 5000,
       // cssEase: "linear"
       responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

      };

      const [over, setOver] = useState(false);
      const [over2, setOver2] = useState(false);
      const [over3, setOver3] = useState(false);
      const [over4, setOver4] = useState(false);
      const [over5, setOver5] = useState(false);
      return (
        <div className="mainContainer container-wrapper">
          <div className=" container container-gap ">
              <div className="partner-header">
                <h3> {t(`Partners.title`)}</h3>
                <p>{t(`Partners.text`)}</p>
              </div>
              <Slider {...settings} className="container">
              
                    <div className="partner-logo-img"
                    onMouseOver={() => setOver(true)}
                    onMouseOut={() => setOver(false)}
                    > 
                      <a href="https://www.wih-dugongocement.com/" target="_blank"><img src={over ? dugongo : dugongoGray} alt="" /></a>
                    </div>
                    <div className="partner-logo-img"
                    onMouseOver={() => setOver2(true)}
                    onMouseOut={() => setOver2(false)}>
                       <a href="http://www.westinternational.cn/"  target="_blank"><img src={over2 ? wihlogo : wihGray} alt="" /></a>
                    </div>
                    <div className="partner-logo-img"
                    onMouseOver={() => setOver3(true)}
                    onMouseOut={() => setOver3(false)}
                    >
                       <a href="https://www.glc-congo.com/"  target="_blank"><img src={over3 ? GLC : glcGray} alt="" /></a>
                    </div>
                     <div className="partner-logo-img"
                    onMouseOver={() => setOver5(true)}
                    onMouseOut={() => setOver5(false)}
                    >
                       <a href="https://redacaciagypsum.com/"  target="_blank"><img src={over5 ? redLogo : redGray} alt="" /></a>
                    </div>
                    <div className="partner-logo-img"
                     onMouseOver={() => setOver4(true)}
                     onMouseOut={() => setOver4(false)}
                    > 
                       <a href="https://www.anjiaconstruction.com/"  target="_blank"><img src={over4 ? anjiaNig : anjiaNigGray} alt="" /></a>
                    </div>
                   
              </Slider>
          </div>
              
        </div>
      );
    
  }
  export default  PartnerSlider;