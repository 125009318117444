import React from "react";
import VirtualtourDetail3 from "../../components/VirtualTour/VirtualtourDetail3";

const VirtualTourDetail3 = () => {
  return <div>
    <VirtualtourDetail3 />
  </div>;
};

export default VirtualTourDetail3;
