import * as React from "react";
import { useState, useRef } from "react";
import "./_PrivacyPolicy.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation, Trans } from 'react-i18next';

function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const [toggleState, setToggleState] = useState(1);


  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (

    <div className="container-wrapper terms-wrapper">
      <div className="container container-gap terms-comp">
        <h2>{t('nav.15') }</h2>
      <section>
        <h4>{t('PrivacyPolicy.17')}</h4>
        <p>{t('PrivacyPolicy.18')}</p>
      </section>
      <section>
        <h4>{t('PrivacyPolicy.19')}</h4>
        <p>{t('PrivacyPolicy.20')}</p>
      </section>
      <section>
        <h4>{t('PrivacyPolicy.21')}</h4>
        <p>{t('PrivacyPolicy.22')}</p>
      </section>
      <section>
        <h4>{t('PrivacyPolicy.23')}</h4>
        <p>{t('PrivacyPolicy.24')}</p>
      </section>
      <section>
        <h4>{t('PrivacyPolicy.25')}</h4>
        <p>{t('PrivacyPolicy.26')}</p>
      </section>
      <section>
        <h4>{t('PrivacyPolicy.27')}</h4>
        <p>{t('PrivacyPolicy.28')}</p>
      </section>
      <section>
        <h4>{t('PrivacyPolicy.29')}</h4>
        <p>{t('PrivacyPolicy.30')}</p>
      </section>
    </div>
  </div>
  );
}

export default PrivacyPolicy;
