import React from "react";
import "../App.css";
import "../components/BannerHome/_BannerHome.scss";
import BannerHome from "../components/BannerHome/BannerHome";
import HomeAbout from "../components/HomeAbout/HomeAbout";
import WhyUsHome from "../components/WhyUsHome/WhyUsHome";
import HomeVideoGallery from "../components/HomeVideoGallery/HomeVideoGallery";
import NewsHome from "../components/NewsHome/NewsHome";
import Testimonials from "../components/Testimonials/Testimonials";
import PartnerSlider from "../components/Partners/Partners";
import ProductCard from "../components/HomeProduct/HomeProductNew/ProductCard";
import AboutPromo from "../components/AboutPromo/AboutPromo";
import Numbers from "../components/HomeNumbers/Numbers";
function Home() {
  return (
    <>
      <BannerHome />
      <HomeAbout />
      <Numbers />
      <ProductCard />
      <WhyUsHome />
      <NewsHome />
      <HomeVideoGallery />
      <Testimonials />
      <AboutPromo />
      <PartnerSlider />
    </>
  );
}

export default Home;
