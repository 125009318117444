import "./OurTeam.scss"
import img1 from "../../img/image4.jpg"
import img2 from "../../img/image5.jpg"
import img3 from "../../img/image7.jpg"
import img4 from "../../img/image6.jpg"
import { useTranslation } from "react-i18next"
import Fade from "react-reveal/Fade";
export default function OurTeam() {
    const {t} = useTranslation()
    return (
        <div className="container-wrapper our-team-wrapper">
            <div className="container container-gap our-team">
                <Fade bottom distance="10%">
                    <div className="top">
                        <h2>{t('Leaders.12')}</h2>
                        <p>{ t('Leaders.15')}</p>
                    </div>
                </Fade>
                    <div className="imgs-wrapper">
                        <Fade left distance="5%">
                            <div className="img"><img src={img1} alt="our team" /></div>
                        </Fade>
                        <Fade right distance="15%">
                            <div className="img"><img src={img2} alt="our team" /></div>
                        </Fade>
                        <Fade left distance="10%">
                            <div className="img"><img src={img3} alt="our team" /></div>
                        </Fade>
                        <Fade right distance="10%">
                            <div className="img"><img src={img4} alt="our team" /></div>
                        </Fade>
                    </div>
            </div>
        </div>
    )
}