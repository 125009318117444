import "./NewsDetail.scss";
import { NavLink } from "react-router-dom";
import SidebarContact from "./SidebarContact";
import SliderImage from "./SliderImage";
import PageNumber from "./PageNumber";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function NewsDetail(props) {
  const { t } = useTranslation();
  const location = useLocation();
  // console.log(location.pathname)

  return (
    <div className="container-wrapper news-detail-wrapper">
      <div className="container container-gap news-detail">
        <div className="news-detail-left">
          <div className="top">
            <div className="title">Latest News</div>
            <div className="row-wrapper">
              <div className="row">
                {props.newsData.map((data, idx) => {
                  if (idx < 3) {
                    return (
                      <NavLink to={"/News/" +  props.pages[idx]}>
                        <div
                          className={
                            "/News/" + props.pages[idx] === location.pathname
                              ? "news-detail-card news-detail-card-active"
                              : "news-detail-card"
                          }
                        >
                          <div className="news-detail-imgs">
                            <img src={data.newsImage[0]} alt="" />
                          </div>
                          <div className="sidebar-content">
                            <div className="headline">{data.title}</div>
                            <div className="date">{data.date}</div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
              <div className="row">
                {props.newsData.map((data, idx) => {
                  if (idx < 6 && idx > 2) {
                    return (
                      <NavLink to={"/News/" + props.pages[idx]}>
                        <div
                          className={
                            "/News/" + props.pages[idx] === location.pathname
                              ? "news-detail-card news-detail-card-active"
                              : "news-detail-card"
                          }
                        >
                          <div className="news-detail-imgs">
                            <img src={data.newsImage[0]} alt="" />
                          </div>
                          <div className="content">
                            <div className="headline">{data.title}</div>
                            <div className="date">{data.date}</div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="sidebar-contact-wrapper">
            <SidebarContact />
          </div>
        </div>
        <div className="news-detail-right">
          <h3 className="headline">{props.headline}</h3>
          <p className="date">{props.date}</p>

          <SliderImage newsImage={props.newsImage} />

          {props.newsText.map((data, idx) => {
            return <p className="text">{data}</p>;
          })}

          <PageNumber pid={props.pid} pages={props.pages} newsData={props.newsData} mainPage="/News/" />
        </div>
      </div>
    </div>
  );
}
