import React from "react";
import "./VirtualtourDetail3.scss";
import CulvertVirtualDetail from "../VirtualTour/CulvertVirtualDetail.png";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const VirtualTourDetail3 = () => {
  return (
    <div>
      <div className="virtual-detail-close">
        <Link to="/Virtual">
          <FaTimes size="24px" />
        </Link>
      </div>
      <div className="virtual-detail-container">
        <div className="virtual-detail-wrapper">
          <div className="virtual-detail-img1">
            <img src={CulvertVirtualDetail} alt="Prefab Production" />
          </div>
          <div className="virtual-detail-content">
            <h2>Campo de produção de manilhas de betào:</h2>
            <p>
              Estamos diante de um produto chamado manilha ou tubo de betão.
              Feito á betão tem a finalidade de esgotar água nas estradas e
              também pode ser usado de mesmo modo em residências. Este material
              vem em principais modelos que são 300mm,
              400mm，500mm，600mm，800mm，1000 mm e tambem 1200mm.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualTourDetail3;
