import "../ProductDetailComponents/Table.scss";
import { useTranslation, Trans } from "react-i18next";

export default function Table2() {
  const { t, i18n } = useTranslation();

  return (

    <div className="container-wrapper">
      <div className="container container-gap specification-table">
        <div className=" product-spec">
          <h3>{t('productDetails.spec')}</h3>
          <hr className="prod-detail-hr-line" />
        </div>
        <div className="table-container">
          <table className="table-spec-1">
            <thead>
              <th>
                <p>{t("gypsumProductDetail.1")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.2")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.3")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.4")}</p>
              </th>
              <th>
                <p>{t("gypsumProductDetail.4-1")}</p>
              </th>
            </thead>
            <tbody>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>9mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>{t("gypsumProductDetail.4-2")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>238</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>9mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>{t("gypsumProductDetail.4-2")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>244</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>9mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>{t("gypsumProductDetail.4-2")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>245</p>
                </td>
              </tr>
              <tr>
                <td data-label={t("gypsumProductDetail.1")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.2")}>
                  <p>595</p>
                </td>
                <td data-label={t("gypsumProductDetail.3")}>
                  <p>9mm</p>
                </td>
                <td data-label={t("gypsumProductDetail.4")}>
                  <p>{t("gypsumProductDetail.4-2")}</p>
                </td>
                <td data-label={t("gypsumProductDetail.5")}>
                  <p>256</p>
                </td>
              </tr>
              {" "}
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
}
