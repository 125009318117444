import React from "react";
import "./Numbers.scss";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 300,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const Numbers = () => {
  const { t } = useTranslation();

  const NumericData = [
    {
      icon: "/PrefabFFF.svg",
      numberProp: 12900,
      exp: "",
      description: t(`Numbers.prefab`),
    },
    {
      icon: "/PoleFFF.svg",
      numberProp: 2500,
      exp: "",
      description: t(`Numbers.pole`),
    },
    {
      icon: "/BlockFFF.svg",
      numberProp: 900,
      exp: "K",
      description: t(`Numbers.brick`),
    },
    {
      icon: "/PipeFFF.svg",
      numberProp: 900,
      exp: "",
      description: t(`Numbers.culvert`),
    },
  ];
  return (
    <div className="container-wrapper numbers-div">
      <div className="container container-gap numbers-wrapper">
        <Fade bottom distance="10%">
          <div className="numbers-header">
            <h2>
              {t(`Numbers.title1`)}
              <span className="top-title-color"> {t(`Numbers.title2`)}</span>
            </h2>
          </div>
        </Fade>

        <div className="numbers-container ">
          {NumericData.map((numData) => {
            return (
              <Fade bottom cascade>
                <div className="num-data">
                  <div className="icon">
                    <img src={numData.icon} alt="" />
                  </div>
                  <div className="num">
                    <h2>
                      <Number n={numData.numberProp} /> {numData.exp}{" "}
                    </h2>
                  </div>
                  <div className="description">
                    <h5>{numData.description} </h5>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
        <Fade bottom distance="10%">
          <p className="bottom-text">
            {t(`Numbers.text1`)}
            <span className="bottom-text-color">{t(`Numbers.text2`)}</span>{t(`Numbers.text3`)}
          </p>
        </Fade>
      </div>
      <div className="num-overlay"></div>
    </div>
  );
};

export default Numbers;
