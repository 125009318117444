import React from "react";
import "./ProductCard.scss";
import { HiOutlinePlusSm } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';


//console.log(ProdData)

const ProductCard = (props) => {
  const { t } = useTranslation();
  const ProdData = [
    {
      id: 1,
      img: "PrefabCardImg.png",
      icon: "PrefabIcon.svg",
      title: t("homeProductCard.prefabTitle"),
      text: t("homeProductCard.prefabDesc"),
      link: "/products/prefab"
    },
    {
      id: 2,
      img: "PoleCardImg.jpg",
      icon: "PoleIcon.svg",
      title: t("homeProductCard.poleTitle"),
      text: t("homeProductCard.poleDesc"),
      link: "/products/pole"
    },

    {
      id: 3,
      img: "BlockCardImg.jpg",
      icon: "BlockIcon.svg",
      title: t("homeProductCard.blockTitle"),
      text: t("homeProductCard.blockDesc"),
      link: "/products/brick"
    },

    {
      id: 4,
      img: "PipeCardImg.jpg",
      icon: "PipeIcon2.svg",
      title: t("homeProductCard.pipeTitle"),
      text: t("homeProductCard.pipeDesc"),
      link: "/products/pipe"
    },
  ];
  // const Cards = ProdData.map((data) => {
  //   return (
  //     <Card
  //       img={data.img}
  //       icon={data.icon}
  //       title={data.title}
  //       text={data.text}
  //     />
  //   );
  // });
  return (
    <div>
      <div className="container-wrapper prodcard-wrapper">
        <div className="container container-gap prod-container">
          <Fade bottom distance="10%">
            <div className="prod-header">
              <div className="prod-title">
                <h2>
                  {t('homeProductCard.mainTitle')}
            
                </h2>
              </div>
              <NavLink to="/products/gypsum-board" className="prod-main-btn">
                <span>{t('homeProductCard.btn')}</span>{" "}
                <HiOutlinePlusSm color="white" size={24} />
              </NavLink>
            </div>
          </Fade>
          <Fade bottom distance ="10%">
            <div className="prod-grid">
              {ProdData.map((data) => {
                return (
                  <Card
                    img={data.img}
                    icon={data.icon}
                    title={data.title}
                    text={data.text}
                    link={data.link}
                  />
                );
              })}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
/* <div className="prod-card-headline">
          <h2> Versatile Building Products</h2>
        </div> */
