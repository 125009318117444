import React from 'react'
import "./AboutPromo.scss"
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
const AboutPromo = () => {
     const {t} = useTranslation()
  
return (
  <div className="home-promo-wrapper container-wrapper">
    <div className="home-promo container">
          <Fade left cascade distance="10%">
            <div className="promo-content">
              <h3 className="title">
             {t(`HomePromo.title`)}
              </h3>
              <p className="text">
              {t(`HomePromo.description`)}
              </p>
              <NavLink to="/Contact">
                <div className="abt-promo-btn"><span>{t(`HomePromo.btn`)}</span></div>
              </NavLink>
            </div>
          </Fade>
    </div>
    <div className="overlay"></div>
  </div>
);
}

export default AboutPromo


