import React from "react";
import "../App.css";
import "../components/Banner/_Banner.scss";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import ProjectCases from "../components/ProjectCases/ProjectCases";
import { useTranslation, Trans } from 'react-i18next';

function Project() {
  const { t, i18n } = useTranslation();
  const slides = [
    {
      url: "banner-project.jpg",
      title: t('nav.10'),
      // body: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      <div className="banner-main-container">
        <Banner slides={slides[0]} />
      </div>
      < ProjectCases />
      </>
  );
}

export default Project;