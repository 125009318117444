import React from "react";
import "./ProductChar.scss";
import { BsCheck2Square } from "react-icons/bs";
import { useTranslation, Trans } from 'react-i18next';

const ProductChar = (props) => {

  const { t, i18n } = useTranslation();

  return (
    <div className="container-wrapper">
      <div className="container container-gap prod-char">
        <div className="prod-char-wrapper">
          <h3>{t('productDetails.char')}</h3>
          <hr className="prod-detail-hr-line" />

          {props.chars.map((data, idx) => {
            return (
              <div key={idx} className="prod-char-list">
            <span>
              <BsCheck2Square size={20} style={{ fill: "#F77B00" }} />
            </span>
            <p>{data}</p>
          </div>
            )
          })}
          
          {/* <div className="prod-char-list">
            <span>
              <BsCheck2Square size={20} style={{ fill: "#F77B00" }} />
            </span>
            <p>{props.char2}</p>
          </div>
          <div className="prod-char-list">
            <span>
              <BsCheck2Square size={20} style={{ fill: "#F77B00" }} />
            </span>
            <p>{props.char3}</p>
          </div>
          <div className="prod-char-list">
            <span>
              <BsCheck2Square size={20} style={{ fill: "#F77B00" }} />
            </span>
            <p>{props.char4}</p>
          </div>
          <div className="prod-char-list">
            <span>
              <BsCheck2Square size={20} style={{ fill: "#F77B00" }} />
            </span>
            <p>{props.char5}</p>
          </div> */}
          <div className="prod-char-remark">
            <p>
              {" "}
              <span className="prod-remark">{props.remark}</span>{" "}
              {props.remarkText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductChar;
