import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import CollapsibleHeader from "./collapsible-header";
import SidebarContact from "./SidebarContact";
import { ProductData } from "./ProductData";

export default function ProductList(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const listRef = useRef([]);
  const [height, setHeight] = useState();

  const [clicked, setClicked] = useState(false);
  const [current, setCurrent] = useState(false)

  function toggle(index) {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);

  }

  function displayHeight(height) {
    console.log(height)
  }

  displayHeight(height)

  function toggleNew(path, index) {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
    navigate(path);
    // window.scrollTo(0, 300);
    setCurrent(false)
    const newHeight = listRef.current[index].clientHeight;
    setHeight(newHeight);
  }

  const navigate = useNavigate();
  const goToTop = (path,index) => {
    navigate(path);
    // window.scrollTo(0, 300);
    setCurrent(index)
  };

  function showCurrentProduct(path) {
    if (
      path === "/products/gypsum-board" ||
      path === "/products/gypsum-board/ordinary" ||
      path === "/products/gypsum-board/moisture" ||
      path === "/products/gypsum-board/water" ||
      path === "/products/gypsum-board/fire" ||
      path === "/products/gypsum-board/coated"
    ) {
      toggleNew(path, 0)
      setClicked(0);
    }
    if (
      path === "/products/prefab/resedential" ||
      path === "/products/prefab/school" ||
      path === "/products/prefab/apartment" ||
      path === "/products/prefab"
    ) {
      toggleNew(path, 1)
      setClicked(1);
    }
    if (
      path === "/products/brick" ||
      path === "/products/brick/hollow" ||
      path === "/products/brick/solid" ||
      path === "/products/brick/pave" ||
      path === "/products/brick/curb"
    ) {  
      toggleNew(path, 2)
      setClicked(2);
    }
    if (path === "/products/pipe") {
      toggleNew(path, 3)
      setClicked(3);
    }
    if (path === "/products/pole") {
      toggleNew(path, 4)
      setClicked(4);
    }
    if (path === "/products/gypsum-board/ordinary") {
      setCurrent(0)
    }
    if (path === "/products/gypsum-board/moisture") {
      setCurrent(1)
    }
    if (path === "/products/gypsum-board/water") {
      setCurrent(3)
    }
    if (path === "/products/gypsum-board/fire") {
      setCurrent(2)
    }
    if (path === "/products/gypsum-board/coated") {
      setCurrent(4)
    }
    if (path === "/products/prefab/resedential") {
      setCurrent(1+0)
    }
    if (path === "/products/prefab/school") {
      setCurrent(1+1)
    }
    if (path === "/products/prefab/apartment") {
      setCurrent(1+2)
    }
    if (path === "/products/brick/hollow") {
      setCurrent(2 + 0)
    }
    if (path === "/products/brick/solid") {
      setCurrent(2 + 1)
    }
    if (path === "/products/brick/pave") {
      setCurrent(2 + 2)
    }
    if (path === "/products/brick/curb") {
      setCurrent(2 + 3)
    }
  }

  useEffect(() => {
    showCurrentProduct(location.pathname);
  }, [location.pathname]);

  return (
    <div className="product-list">
      <CollapsibleHeader label="Product List">
        {ProductData.map((item, index) => {
          return (
            <div key={index}>
              <div
                className="collapsible--btn"
                onClick={() => toggleNew(item.url,index)}
                style={
                  clicked === index
                    ? { background: "#009cad", color: "#fff" }
                    : { background: "#eee", color: "#222" }
                }
              >
                <h5 >{t(`${item.main}`)}</h5>
                <span>
                  {item.sub && clicked === index ? (
                    <BsChevronDown />
                  ) : (
                    <BsChevronRight />
                  )}
                </span>
              </div>
              <div
                className="content-parent" 
               
                style={
                  item.sub && clicked === index
                    ? { height: height + "px" }
                    : { height: "0px" }
                }   
              >
                <div className="content"  ref={(element) => listRef.current.push(element)}>
                  {ProductData[index].list.map((prodItem, idx) => {
                    return (
                      <>
                        <p 
                          className= {(current === (index + idx)) ? "content--list list-active" : "content--list"}
                          key={index + idx}
                          onClick={() => goToTop(item.listUrl[idx], (index + idx))}
                        >
                          <div>
                            <BsChevronRight style={{ color: (current === (index + idx)) ?   "#f77b00" : "#d1d1d1" }} />
                          </div>
                          <div className="collapsible--list">{t(`${prodItem}`)}</div>
                        </p>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </CollapsibleHeader>
      <SidebarContact
        content1="+258 864 056 883"
        content2="anjiamoz@westholdinggroup.com"
        content3="Mozambique, Maputo, Mudada, BelaVista, Matutuine, 2200 Mozambique"
        title1="Tel:"
        title2="Email:"
        title3="Address:"
        icon3="/location--icon.png"
        contact="/Contact"
        url="https://goo.gl/maps/mPvki9Zh1NdF2s8E9"
      />
    </div>
  );
}
